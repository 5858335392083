import Div from '@hellodarwin/core/lib/components/common/div';
import Loading from '@hellodarwin/core/lib/components/loading';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/index';
import {
  fetchAllProjectsPreview,
  selectAllProjectsPreview,
  selectProjectsIsLoading,
} from '../../../features/api/slices/projects-slice';
import { useNewClientApi } from '../../../features/api/use-client-api';
import PriorityTile from '../priority-tile';
import EmptyPriorities from './empty-priorities';

export interface PrioritiesTableProps {}

const PrioritiesTable: React.FC<PrioritiesTableProps> = () => {
  const api = useNewClientApi();
  const dispatch = useAppDispatch();
  const projects = useAppSelector(selectAllProjectsPreview);
  const isLoading = useAppSelector((state) =>
    selectProjectsIsLoading(state, 'allProjects'),
  );

  useEffect(() => {
    dispatch(fetchAllProjectsPreview({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Div flex="column" gap={16}>
      {isLoading ? (
        <Loading />
      ) : !!projects.length ? (
        projects.map((p) => <PriorityTile priority={p} />)
      ) : (
        <EmptyPriorities />
      )}
    </Div>
  );
};
export default PrioritiesTable;
