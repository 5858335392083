import styled from '@hellodarwin/core/lib/plugins/styled';

export const PriorityTileContainer = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 32px;
  color: ${({ theme }) => theme.colors.grey_1};
  background: ${({ theme }) => theme.colors.beige_2};
  border: 1px solid ${({ theme }) => theme.colors.grey_1};
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};
    color: ${({ theme }) => theme.colors.grey_1};
    background: ${({ theme }) => theme.colors.purple_5};
  }
`;
