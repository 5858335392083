import Button from '@hellodarwin/core/lib/components/common/button';
import ModalLayoutWithIcon from '@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-icon';
import ProviderCard from '@hellodarwin/core/lib/components/rfp/status-flow/choose-partner-flow/chosen-sp/card';
import { Match } from '@hellodarwin/core/lib/features/entities';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import MoreCandidates from '@hellodarwin/icons/dist/icons/MoreCandidates';
import { useAppDispatch, useAppSelector } from '../../../../app/index';
import { openMatchModal } from '../../../../features/api/slices/global-slice';
import {
  selectRfpMatchById,
  selectShortlistedMatches,
  selectUnshortlistedMatches,
} from '../../../../features/api/slices/matches-slice';
import {
  selectRfpById,
  selectSelectedRfpId,
} from '../../../../features/api/slices/rfp-slice';
import {
  CandidatesModalProvidersList,
  CandidatesModalProvidersScroll,
} from './styles';
type ShortlistMoreCandidateModalProps = {
  open: boolean;
  handleClose: () => void;
  matchId?: string;
};

const ShortlistMoreCandidateModal = ({
  open,
  handleClose,
  matchId,
}: ShortlistMoreCandidateModalProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const rfpId = useAppSelector(selectSelectedRfpId) || '';
  const rfp = useAppSelector((state) => selectRfpById(state, rfpId));

  const selectedMatch = useAppSelector((state) =>
    selectRfpMatchById(state, matchId || ''),
  );

  const unselectedMatches = useAppSelector(selectUnshortlistedMatches);
  const shortlistedMatches = useAppSelector(selectShortlistedMatches);

  //Functions
  const handleProviderClick = (match: Match) => {
    dispatch(
      openMatchModal({
        isVisible: true,
        type: 'shortlist',
        matchId: match.match_id,
        source: 'Client-More',
      }),
    );
  };

  if (!rfp || !unselectedMatches.length) return <></>;

  return (
    <ModalLayoutWithIcon
      open={open}
      handleCancel={handleClose}
      size={'large'}
      Icon={MoreCandidates}
      iconSize={64}
      title={t('rfp|modal.shortlistMoreCandidates.title')}
      description={t('rfp|modal.shortlistMoreCandidates.text', {
        count: rfp.provider_max,
      })}
      Actions={
        <>
          <Button onClick={handleClose} isLink style={{ margin: 'auto' }}>
            {t('rfp|modal.shortlistMoreCandidates.cancel')}
          </Button>
        </>
      }
      warning={t('rfp|modal.shortlistMoreCandidates.remaining', {
        count: rfp.provider_max - shortlistedMatches.length,
      })}
    >
      <CandidatesModalProvidersScroll>
        <CandidatesModalProvidersList $listLength={unselectedMatches.length}>
          {unselectedMatches.map((m) => (
            <ProviderCard
              key={m.match_id}
              provider={m.provider}
              onClick={() => handleProviderClick(m)}
              selected={selectedMatch?.match_id === m.match_id}
            />
          ))}
        </CandidatesModalProvidersList>
      </CandidatesModalProvidersScroll>
    </ModalLayoutWithIcon>
  );
};

export default ShortlistMoreCandidateModal;
