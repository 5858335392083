var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Warning from '@hellodarwin/icons/dist/icons/Warning';
import Modal from '..';
import Div from '../../div';
import { useTheme } from 'styled-components';
const ModalWarning = (_a) => {
    var _b;
    var { children } = _a, modalProps = __rest(_a, ["children"]);
    const theme = useTheme();
    return (_jsx(Modal, Object.assign({}, modalProps, { styles: Object.assign(Object.assign({}, modalProps === null || modalProps === void 0 ? void 0 : modalProps.styles), { content: Object.assign({ overflow: 'visible' }, (_b = modalProps === null || modalProps === void 0 ? void 0 : modalProps.styles) === null || _b === void 0 ? void 0 : _b.content) }), children: _jsxs(Div, { style: { paddingTop: 40, paddingBottom: 16 }, flex: 'column', align: 'center', children: [_jsx(Div, { flex: 'row', align: 'center', justify: 'center', backgroundColor: theme.colors.yellow_2, style: {
                        width: 100,
                        height: 100,
                        borderRadius: 99,
                        position: 'absolute',
                        top: 0,
                        transform: 'translateY(-50%)',
                    }, children: _jsx(Warning, { width: 64, height: 64, style: { marginBottom: 3 } }) }), children] }) })));
};
export default ModalWarning;
