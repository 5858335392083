import { css, DefaultTheme } from '@hellodarwin/core/lib/plugins/styled';

export const getBusinessPrioritySingleSectionStyling = (
  theme: DefaultTheme,
) => css`
  background: ${theme.colors.white_1};
  border: 1px solid ${theme.colors.grey_1};
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
`;
