import { jsx as _jsx } from "react/jsx-runtime";
import { AppName } from '../../../../../features/entities';
import getMeetingUrl from '../../../../../features/helpers/get-meeting-url';
import useLocale from '../../../../../features/providers/locale-provider';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import HubspotMeeting from '../../../../hubspot/hubspot-meeting';
import RfpStatusFlowLayout from '../../rfp-status-flow-layout';
const NoPartnerSelectedBookConsult = ({ handleClose, }) => {
    const { t } = useTranslations();
    const { selectedLocale } = useLocale();
    const pageSettings = {
        url: getMeetingUrl(selectedLocale, 'website'),
        backgroundColor: 'ffffff',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: '00a2ff',
        textColor: '4d5055',
        width: '100%',
        height: 950,
    };
    return (_jsx(RfpStatusFlowLayout, { buttons: _jsx(Div, { children: _jsx(Button, { onClick: handleClose, withArrowLeft: true, children: t('button|back') }) }), children: _jsx(HubspotMeeting, { meetingUrl: pageSettings.url, app: AppName.Client }) }));
};
export default NoPartnerSelectedBookConsult;
