import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';
import { PropsWithChildren } from 'react';
import {
  ProjectSingleHeaderInfoBody,
  ProjectSingleHeaderInfoContainer,
} from './styles';

interface ProjectSingleHeaderInfoProps extends PropsWithChildren {
  label: string;
  Icon: IconNode;
  highlighted?: boolean;
}
const ProjectSingleHeaderInfo = ({
  label,
  children,
  Icon,
  highlighted,
}: ProjectSingleHeaderInfoProps) => {
  const theme = useTheme();
  return (
    <ProjectSingleHeaderInfoContainer>
      <Div
        flex="row"
        align="center"
        gap={8}
        style={{ color: theme.colors.grey_2 }}
      >
        <Icon size={14} />
        <Typography elementTheme="overline" nowrap>
          {label}
        </Typography>
      </Div>
      <ProjectSingleHeaderInfoBody>
        <Typography
          elementTheme="body2"
          textAlign="left"
          color={highlighted ? theme.colors.purple_2 : undefined}
          medium={highlighted}
          style={{ width: '100%' }}
        >
          {children}
        </Typography>
      </ProjectSingleHeaderInfoBody>
    </ProjectSingleHeaderInfoContainer>
  );
};

export default ProjectSingleHeaderInfo;
