import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { CancelRfpFlowSteps, } from '../../../../features/entities';
import CancelConfirmation from './cancel-confirmation';
import CancelReasonForm from './cancel-reason-form';
const CancelRfpFlow = ({ handleClose, editionFunctions, rfp, flowStep, setFlowStep, }) => {
    const handleCancelRfp = (selected, otherReasonSpecified) => {
        if (!!(editionFunctions === null || editionFunctions === void 0 ? void 0 : editionFunctions.cancel)) {
            editionFunctions === null || editionFunctions === void 0 ? void 0 : editionFunctions.cancel(rfp.rfp_id, selected, otherReasonSpecified).then((i) => {
                setFlowStep(CancelRfpFlowSteps.cancelConfirmation);
            });
        }
    };
    return (_jsx(_Fragment, { children: flowStep === CancelRfpFlowSteps.cancelFormStep ? (_jsx(CancelReasonForm, { handleClose: handleClose, handleCancelProject: handleCancelRfp })) : (flowStep === CancelRfpFlowSteps.cancelConfirmation && (_jsx(CancelConfirmation, { handleClose: handleClose }))) }));
};
export default CancelRfpFlow;
