import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ChoosePartnerFlowSteps, } from '../../../../../features/entities';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import RfpStatusFlowLayout from '../../rfp-status-flow-layout';
import ProviderCard from './card';
const ChosenSp = ({ shortlistMatches, setStep, setSelectedMatch, selectedMatch, editionFunctions, }) => {
    const { t } = useTranslations();
    const handleNext = () => {
        if (!!editionFunctions.selectAsWinner) {
            editionFunctions.selectAsWinner(selectedMatch.match_id);
        }
        setStep(ChoosePartnerFlowSteps.progressStep);
    };
    const handleProviderClick = (match) => {
        setSelectedMatch(match);
    };
    if (!shortlistMatches)
        return _jsx(_Fragment, {});
    return (_jsx(RfpStatusFlowLayout, { title: t('projectStatusFlow|psf_chosenSp_header1'), description: t('projectStatusFlow|psf_chosenSp_text1'), buttons: _jsx(Button, { onClick: handleNext, tablet: { fullWidth: true }, size: "small", disabled: !(selectedMatch === null || selectedMatch === void 0 ? void 0 : selectedMatch.provider_id), children: t('button|next') }), children: _jsx(_Fragment, { children: _jsx(Div, { flex: "row", gap: 32, align: "stretch", justify: "center", children: React.Children.toArray(shortlistMatches.map((m) => (_jsx(ProviderCard, { provider: m.provider, onClick: () => handleProviderClick(m), selected: selectedMatch.provider_id === m.provider_id }, m.match_id)))) }) }) }));
};
export default ChosenSp;
