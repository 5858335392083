var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from '..';
import parse from '../../../../features/helpers/parse';
import Div from '../../div';
import Typography from '../../typography';
import { ModalAvatar } from '../styles';
const ModalLayoutWithIcon = (_a) => {
    var _b, _c;
    var { avatarUrl, avatarName, iconSize = 64, title, description, warning, children, Actions, noPadding } = _a, modalProps = __rest(_a, ["avatarUrl", "avatarName", "iconSize", "title", "description", "warning", "children", "Actions", "noPadding"]);
    const logoContainerSize = iconSize;
    const midLogoContainerSize = logoContainerSize / 2;
    return (_jsxs(Modal, Object.assign({}, modalProps, { styles: Object.assign(Object.assign({}, modalProps === null || modalProps === void 0 ? void 0 : modalProps.styles), { body: Object.assign({ overflow: 'auto', height: '100%' }, (_b = modalProps === null || modalProps === void 0 ? void 0 : modalProps.styles) === null || _b === void 0 ? void 0 : _b.body), content: Object.assign({ padding: noPadding ? 0 : '32px 115px', paddingTop: midLogoContainerSize + 24, overflow: 'visible', maxHeight: `calc(95vh - ${midLogoContainerSize}px)` }, (_c = modalProps === null || modalProps === void 0 ? void 0 : modalProps.styles) === null || _c === void 0 ? void 0 : _c.content) }), children: [_jsx(ModalAvatar, { style: {
                    position: 'absolute',
                    top: -midLogoContainerSize,
                    left: '50%',
                    transform: 'translateX(-50%)',
                }, shape: "round", size: iconSize, src: avatarUrl, children: avatarName[0] }), _jsxs(Div, { flex: "column", gap: 32, align: "center", children: [_jsxs(Div, { flex: "column", gap: 16, align: "center", children: [_jsx(Typography.Title, { level: 2, elementTheme: "h4", textAlign: "center", children: title }), description && (_jsx(Typography, { textAlign: "center", children: parse(description) })), !!warning && (_jsx(Typography, { bold: true, elementTheme: "body2", letterSpacing: 0.32, textAlign: "center", children: warning }))] }), _jsx(Div, { children: children }), _jsx(Div, { flex: "row", gap: 24, align: "center", fitMaxContent: true, style: { width: '100%' }, tablet: { flex: 'column-reverse' }, children: Actions })] })] })));
};
export default ModalLayoutWithIcon;
