import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '../../../../../plugins/styled';
import Avatar from '../../../../common/avatar';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import ReviewRatings from '../../../../reviews/ratings';
import { ProviderCardContainer } from './styles';
const ProviderCard = ({ provider, onClick, selected }) => {
    var _a;
    const theme = useTheme();
    return (_jsx(ProviderCardContainer, { onClick: onClick, hoverable: true, "$isSelected": selected, children: _jsxs(Div, { flex: "column", align: "center", gap: 24, justify: "space-between", children: [_jsxs(Div, { flex: "column", align: "center", gap: 24, children: [_jsx(Avatar, { size: 78, shape: "rounded-corner", src: provider.logo, children: provider.name[0] }), _jsxs(Div, { flex: "column", align: "center", gap: 8, children: [_jsx(Typography.Title, { level: 3, elementTheme: "subtitle2", textAlign: "center", nowrap: true, ellipsis: true, children: provider.name }), _jsxs(Typography, { elementTheme: "caption", color: theme.colors.grey_3, textAlign: "center", children: [provider.city, ", ", provider.province] })] })] }), !!((_a = provider === null || provider === void 0 ? void 0 : provider.reviews) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(Div, { flex: "row", gap: 8, align: "center", fitContent: true, borderRadius: 24, backgroundColor: theme.colors.yellow_4, justify: "center", style: { paddingLeft: '1rem', paddingRight: '1rem' }, children: _jsx(ReviewRatings, { defaultValue: provider.reviews_average || 0, size: "small", showValue: true, textPlacement: "end", allowHalf: true }) }))] }) }));
};
export default ProviderCard;
