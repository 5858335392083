import ModalLayoutWithMeeting from '@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-meeting';
import ModalWarning from '@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-warning';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import ContactModal from '@hellodarwin/core/lib/components/modals/contact-modal';
import { AppName } from '@hellodarwin/core/lib/features/entities';
import getMeetingUrl from '@hellodarwin/core/lib/features/helpers/get-meeting-url';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/app-hooks';
import {
  fetchAdmin,
  selectAdminByAdminId,
  selectIsLoading,
} from '../../../features/api/slices/global-slice';
import { selectProfile } from '../../../features/api/slices/profile-slice';
import { useClientApi } from '../../../features/api/use-client-api';

export interface ManagerContactModalProps {
  open: boolean;
  handleClose: () => void;
  isPropulsion?: boolean;
  adminAuthID: string;
}

const ManagerContactModal = ({
  open,
  handleClose,
  isPropulsion,
  adminAuthID,
}: ManagerContactModalProps) => {
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const api = useClientApi();
  const dispatch = useAppDispatch();

  const profile = useAppSelector(selectProfile);
  const loading = useAppSelector(selectIsLoading);
  const admin = useAppSelector((state) =>
    selectAdminByAdminId(state, adminAuthID ?? 'default'),
  );

  const [meetingModalOpen, setMeetingModalOpen] = useState(false);
  const handleModal = () => setMeetingModalOpen(!meetingModalOpen);

  useEffect(() => {
    dispatch(fetchAdmin({ api, adminId: adminAuthID }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAuthID]);

  const withAdmin = useMemo(() => !!admin?.admin_id?.length, [admin]);

  const meetingUrl = useMemo(() => {
    const url =
      selectedLocale === 'en' ? admin?.meeting_link_en : admin?.meeting_link_fr;

    if (!url) {
      return getMeetingUrl(
        selectedLocale,
        'client',
        isPropulsion ? 'propulsion' : 'projects',
      );
    }

    return url;
  }, [admin, selectedLocale, isPropulsion]);

  if (!withAdmin) {
    return (
      <ModalWarning open={open} size="small" handleCancel={handleClose}>
        <Typography> {t('common|message.noAccountManager')}</Typography>
      </ModalWarning>
    );
  }

  return (
    <>
      <ContactModal
        open={open}
        handleClose={handleClose}
        picture={admin.picture}
        loading={loading}
        name={`${admin.first_name} ${admin.last_name}`}
        infos={[
          {
            label: t(`provider-profile|contact.email`),
            value: admin.email,
            type: 'email',
          },
          {
            label: t(`provider-profile|contact.phone`),
            value: admin.phone,
            type: 'phone',
          },
        ]}
        cta={{
          headingIcon: <Timeline size={16} />,
          text: t('rfp|rfpActionCard.bookAMeeting.title'),
          action: handleModal,
        }}
      />

      <ModalLayoutWithMeeting
        open={meetingModalOpen}
        handleCancel={handleModal}
        meetingUrl={meetingUrl}
        Icon={Timeline}
        title={t('message.talkToManager')}
        name={profile.contact_name}
        phone={profile.contact_phone}
        companyName={profile.company_name}
        app={AppName.Client}
        email={profile.contact_email}
      />
    </>
  );
};

export default ManagerContactModal;
