import useProjectsListState from '@hellodarwin/core/lib/components/projects/hooks/use-projects-list-state';
import MainProjectTableContent from '@hellodarwin/core/lib/components/projects/main-project-table/main-project-table-content';
import { BusinessPrioritySectionBlockProps } from '.';

const ChildProjectsSection: React.FC<BusinessPrioritySectionBlockProps> = ({
  priority,
}) => {
  const projectsListState = useProjectsListState({
    defaultState: 'noEdit',

    projects: [priority],
    grantsPath: '/funding-explorer',
    applicationsPath: '/programs',
    projectPath: `/game-plan/${priority.project_id}`,
  });

  return (
    <MainProjectTableContent
      project={priority}
      projectsListState={projectsListState}
    />
  );
};
export default ChildProjectsSection;
