import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChoosePartnerFlowSteps, RfpProgress, } from '../../../../../features/entities';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import RfpStatusFlowLayout from '../../rfp-status-flow-layout';
import RfpProgressCard from './card';
const ProgressReportStep = ({ setStep, progress, provider, setRfpProgress, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    const handleNext = () => {
        if (!!progress || progress == 0) {
            setStep(ChoosePartnerFlowSteps.QuickReviewStep);
        }
    };
    const handlePrev = () => {
        setStep(ChoosePartnerFlowSteps.chosenSpStep);
    };
    const handleCardClick = (newProgress) => {
        setRfpProgress(newProgress);
    };
    return (_jsx(RfpStatusFlowLayout, { title: t('projectStatusFlow|psf_projectStatus_header1'), description: t('projectStatusFlow|psf_projectStatus_text1', {
            SP: provider.name,
        }), buttons: _jsxs(_Fragment, { children: [_jsx(Button, { defaultStyle: theme.colors.white_1, onClick: handlePrev, tablet: { fullWidth: true }, size: "small", children: t('button|previous') }), _jsx(Button, { tablet: { fullWidth: true }, onClick: handleNext, size: "small", disabled: progress === undefined, children: t('button|next') })] }), children: _jsx(_Fragment, { children: _jsxs(Div, { flex: "row", gap: 16, justify: "center", align: "center", children: [_jsx(RfpProgressCard, { title: t('projectStatusFlow|psf_projectStatus_selectionNotStarted'), value: RfpProgress.NotStarted, onClick: handleCardClick, selected: progress === RfpProgress.NotStarted }), _jsx(RfpProgressCard, { title: t('projectStatusFlow|psf_projectStatus_selectionInProgress'), value: RfpProgress.InProgress, onClick: handleCardClick, selected: progress === RfpProgress.InProgress }), _jsx(RfpProgressCard, { title: t('projectStatusFlow|psf_projectStatus_selectionCompleted'), value: RfpProgress.Completed, onClick: handleCardClick, selected: progress === RfpProgress.Completed })] }) }) }));
};
export default ProgressReportStep;
