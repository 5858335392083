import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Checkbox from 'antd/es/checkbox/Checkbox';
import TextArea from 'antd/es/input/TextArea';
import { PossibleCancelAnswers } from '../../../../../features/entities';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
const ReasonCheckbox = ({ answer, selected, handleChange, handleOtherReasonChange, t, }) => {
    const theme = useTheme();
    const handleCheckboxChange = (e) => {
        const selected = e.target.name;
        if (!!selected) {
            if (Object.values(PossibleCancelAnswers).includes(selected)) {
                handleChange(selected);
            }
        }
    };
    return (_jsxs(Div, { flex: "column", gap: 8, children: [_jsx(Checkbox, { checked: selected === answer, name: answer, onChange: handleCheckboxChange, style: { alignItems: 'flex-start', gap: 8 }, children: _jsxs(Div, { flex: "row", align: "center", gap: 3, children: [_jsx(Typography, { elementTheme: "body2", medium: true, children: t(`projectStatusFlow|psf_cancel_checkbox${answer}`) }), [PossibleCancelAnswers.Other].includes(answer) && (_jsxs(Typography, { color: theme.colors.grey_2, textTransform: "lowercase", elementTheme: "body3", medium: true, children: ["(", t(`projectStatusFlow|psf_cancel_placeholder1`), ")"] }))] }) }, answer), _jsxs("div", { style: { paddingLeft: `calc(16px + 16px)` }, children: [[
                        PossibleCancelAnswers.Rescoping,
                        PossibleCancelAnswers.PartnerSelection,
                    ].includes(answer) &&
                        selected === answer && (_jsx(Typography, { elementTheme: "caption", color: theme.colors.purple_1, children: t(`projectStatusFlow|psf_cancel_infos${answer}`) })), [PossibleCancelAnswers.Other].includes(answer) &&
                        selected === answer && (_jsx(TextArea, { onChange: handleOtherReasonChange, placeholder: t(`projectStatusFlow|psf_cancel_placeholder1`), style: { minHeight: 100 } }))] })] }));
};
export default ReasonCheckbox;
