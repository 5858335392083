import styled from '@hellodarwin/core/lib/plugins/styled';
import { getProjectSingleSectionStyling } from '../styles';

export const ProjectSingleHeaderContainer = styled.div`
  gap: 16px;
  border-radius: 8px 8px 0px 0px;
  ${({ theme }) => getProjectSingleSectionStyling(theme)}
`;
export const ProjectSingleHeaderInfoBody = styled.div`
  padding-inline-start: calc(14px + 10px);
  width: 100%;
  ul {
    margin: 0;
    padding-inline-start: 0;
  }
`;

export const ProjectSingleHeaderInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
