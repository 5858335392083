var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useMemo } from 'react';
import Modal from '..';
import parse from '../../../../features/helpers/parse';
import theme from '../../../../theme';
import Div from '../../div';
import Typography from '../../typography';
const ModalLayoutWithIcon = (_a) => {
    var _b, _c;
    var { Icon, iconSize = 32, iconColor, title, description, warning, children, Actions, direction = 'column', noPadding, withoutBorder } = _a, modalProps = __rest(_a, ["Icon", "iconSize", "iconColor", "title", "description", "warning", "children", "Actions", "direction", "noPadding", "withoutBorder"]);
    const align = useMemo(() => (direction === 'column' ? 'center' : undefined), [direction]);
    const style = useMemo(() => (direction === 'row' ? { flex: 1 } : undefined), [direction]);
    const classes = classNames('hd-modal-with-icon', {
        [`hd-modal-horizontal`]: direction === 'row',
    });
    const midIconSize = useMemo(() => iconSize / 2, [iconSize]);
    return (_jsxs(Modal, Object.assign({}, modalProps, { noPadding: noPadding, styles: Object.assign(Object.assign({}, modalProps === null || modalProps === void 0 ? void 0 : modalProps.styles), { body: Object.assign({ overflow: 'auto', height: '100%' }, (_b = modalProps === null || modalProps === void 0 ? void 0 : modalProps.styles) === null || _b === void 0 ? void 0 : _b.body), content: Object.assign({ padding: noPadding ? 0 : '32px 115px', paddingTop: 54, overflow: 'visible', maxHeight: `calc(95vh - ${midIconSize}px)` }, (_c = modalProps === null || modalProps === void 0 ? void 0 : modalProps.styles) === null || _c === void 0 ? void 0 : _c.content) }), children: [_jsx(Icon, { size: iconSize, color: iconColor, style: {
                    background: theme.colors.white_1,
                    padding: !withoutBorder ? iconSize / 8 : 0,
                    aspectRatio: 1,
                    borderRadius: 99,
                    overflow: 'visible',
                    border: !withoutBorder
                        ? `2px solid ${theme.colors.purple_2}`
                        : undefined,
                    position: 'absolute',
                    top: -midIconSize,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    color: iconColor,
                } }), _jsxs(Div, { flex: "column", align: "center", className: classes, gap: 32, fitContent: true, style: { margin: 'auto' }, children: [_jsxs(Div, { flex: direction, align: "center", gap: 32, fitContent: true, children: [_jsxs(Div, { flex: "column", gap: 24, align: align, style: { width: direction === 'row' ? 350 : undefined }, children: [_jsxs(Div, { style: style, flex: "column", align: align, gap: 20, children: [_jsx(Typography.Title, { level: 3, textAlign: align, children: title }), description && (_jsx(Typography, { textAlign: align, children: parse(description) }))] }), !!warning && (_jsx(Typography, { bold: true, elementTheme: "body2", letterSpacing: 0.32, textAlign: align, children: warning }))] }), !!children && _jsx(Div, { style: style, children: children })] }), _jsx(Div, { flex: "row", gap: 24, align: "center", fitMaxContent: true, style: { width: '100%' }, tablet: { flex: 'column-reverse' }, children: Actions })] })] })));
};
export default ModalLayoutWithIcon;
