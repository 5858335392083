import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import FormItem from '@hellodarwin/core/lib/components/forms/form-layouts/form-item';
import {
  ClientRfpRequest,
  ParsedClientTag,
} from '@hellodarwin/core/lib/features/entities';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import theme from '@hellodarwin/core/lib/theme';
import Budget from '@hellodarwin/icons/dist/icons/Budget';
import Service from '@hellodarwin/icons/dist/icons/Service';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import Form from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import Select, { SelectProps } from 'antd/es/select';
import { useAppDispatch, useAppSelector } from '../../../../app/app-hooks';
import {
  createRfp,
  selectRfpLoading,
} from '../../../../features/api/slices/rfp-slice';
import { useClientApi } from '../../../../features/api/use-client-api';

interface RfpSubmitFormValues {
  rfp_service: string;
  rfp_budget: string;
  premium: string;
  timeline: string;
  rfp_description: string;
}

interface RfpSubmitFormProps {
  closeModal: () => void;
  openSuccesModal?: (arg0: boolean) => void;
  tags: ParsedClientTag[];
}

const RfpSubmitForm = ({
  closeModal,
  openSuccesModal,
  tags,
}: RfpSubmitFormProps) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<RfpSubmitFormValues>();
  const dispatch = useAppDispatch();
  const api = useClientApi();
  const isLoading = useAppSelector(selectRfpLoading);

  const onFinish = () => {
    try {
      form
        .validateFields()
        .then((values) => {
          (async () => {
            let minBudget: string = '0';
            let maxBudget: string = '0';

            if (values.rfp_budget) {
              [minBudget, maxBudget] = values.rfp_budget.split('-');
            }

            const rfp: ClientRfpRequest = {
              original_description: values.rfp_description,
              budget_min: parseInt(minBudget),
              budget_max: parseInt(maxBudget),
              premium: values.premium === 'yes',
              tags: [values.rfp_service],
              timeline: values.timeline,
            };

            await dispatch(createRfp({ api, rfp }));

            form.resetFields();
            openSuccesModal && openSuccesModal(true);
          })();
        })
        .catch((e) => {});
    } catch (e) {}
  };

  return (
    <Div className="fade-in" flex="column" gap={16} align="center">
      <Div flex="column" align="center" gap={8} style={{ maxWidth: 600 }}>
        <Typography.Title level={2} elementTheme="h5" noMargin={false}>
          {t('rfp|submitRfpForm.title')}
        </Typography.Title>
        <Typography elementTheme="body2" textAlign="center">
          {t('rfp|submitRfpForm.description')}
        </Typography>
      </Div>
      <Form
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        style={{ width: '100%' }}
        className={'project-submit-form'}
      >
        <Div flex="column" gap={16}>
          <FormItem
            name="project_service"
            label={t('rfp|submitRfpForm.service')}
            rules={[
              {
                required: true,
                message: t('rfp|submitRfpForm.requiredMessage.service'),
              },
            ]}
          >
            <ServiceSelect tags={tags} />
          </FormItem>
          <Div flex="row" tablet={{ flex: 'column' }} gap={24}>
            <FormItem
              name="project_budget"
              label={t('rfp|submitRfpForm.budget')}
              style={{ width: '100%', flex: 1 }}
              rules={[
                {
                  required: true,
                  message: t('rfp|submitRfpForm.requiredMessage.budget'),
                },
              ]}
            >
              <SelectBudget />
            </FormItem>
            <FormItem
              name="timeline"
              style={{ width: '100%', flex: 1 }}
              label={t('rfp|submitRfpForm.timeline')}
              rules={[
                {
                  required: true,
                  message: t('rfp|submitRfpForm.requiredMessage.timeline'),
                },
              ]}
            >
              <SelectTimeline />
            </FormItem>
          </Div>

          <FormItem
            name="rfp_description"
            label={t('rfp|submitRfpForm.projectDescription')}
            className={'description-row-content'}
            style={{ marginBottom: '2rem', width: '100%' }}
            rules={[
              {
                required: true,
                message: t('rfp|submitRfpForm.requiredMessage.description'),
              },
            ]}
          >
            <TextArea
              rows={4}
              placeholder={t('rfp|submitRfpForm.placeholders.description')}
            />
          </FormItem>
        </Div>
        <Div
          flex={'row'}
          justify={'flex-end'}
          align={'center'}
          gap={16}
          style={{ marginTop: 8 }}
          tablet={{
            flex: 'column-reverse',
            align: 'center',
            justify: 'center',
            gap: 8,
          }}
        >
          <Button
            defaultStyle={theme.colors.white_1}
            htmlType={'reset'}
            onClick={closeModal}
            size={'small'}
            disabled={isLoading}
          >
            {t('common|button.cancel')}
          </Button>
          <Button
            size={'small'}
            htmlType={'submit'}
            onClick={onFinish}
            loading={isLoading}
          >
            {t('common|button.confirm')}
          </Button>
        </Div>
      </Form>
    </Div>
  );
};

const ServiceSelect = ({
  onChange,
  value,
  tags,
}: {
  onChange?: (value: string) => void;
  value?: string;
  tags: ParsedClientTag[];
}) => {
  const { t } = useTranslation();

  const options: SelectProps['options'] = [];

  for (const tag of tags) {
    const suboptions: SelectProps['options'] = [];
    if (tag.services) {
      for (const service of tag.services) {
        suboptions.push({
          label: t(`tags|${service}`),
          value: service,
        });
      }

      options.push({
        label: tag.category,
        options: suboptions,
      });
    }
  }

  return (
    <Select
      placeholder={t('rfp|submitRfpForm.placeholders.service')}
      size={'large'}
      options={options}
      onChange={onChange}
      value={value}
      showSearch
      suffixIcon={<TabArrow size={16} down />}
      prefix={<Service size={16} />}
    />
  );
};

const SelectBudget = ({
  onChange,
  value,
}: {
  onChange?: (value: string) => void;
  value?: string;
}) => {
  const { t } = useTranslation();

  const options: SelectProps['options'] = [
    { label: t('rfp|submitRfpForm.iDontKnow'), value: '0-0' },
    { label: '$0 - $1,000', value: '0-1000' },
    { label: '$1,000 - $2,500', value: '1000-2500' },
    { label: '$2,500 - $5,000', value: '2500-5000' },
    { label: '$5,000 - $10,000', value: '5000-10000' },
    { label: '$10,000 - $25,000', value: '10000-25000' },
    { label: '$25,000 - $50,000', value: '25000-50000' },
    { label: '$50,000 - $100,000', value: '50000-100000' },
    { label: '$100,000 +', value: '100000-100000' },
  ];

  return (
    <Select
      size={'large'}
      placeholder={t('rfp|submitRfpForm.placeholders.budget')}
      options={options}
      onChange={onChange}
      value={value}
      suffixIcon={<TabArrow size={16} down />}
      prefix={<Budget size={16} />}
    />
  );
};

const SelectTimeline = ({
  onChange,
  value,
}: {
  onChange?: (value: string) => void;
  value?: string;
}) => {
  const { t } = useTranslation();

  const options: SelectProps['options'] = [
    {
      label: t('rfp|submitRfpForm.selectOptions.timeline.asap'),
      value: 'As soon as possible (urgent)',
    },
    {
      label: t('rfp|submitRfpForm.selectOptions.timeline.1-2'),
      value: '1 to 6 month',
    },
    {
      label: t('rfp|submitRfpForm.selectOptions.timeline.3-6'),
      value: '3 to 6 month',
    },
    {
      label: t('rfp|submitRfpForm.selectOptions.timeline.other'),
      value: 'other',
    },
  ];

  return (
    <Select
      size="large"
      placeholder={t('rfp|submitRfpForm.placeholders.timeline')}
      options={options}
      onChange={onChange}
      value={value}
      suffixIcon={<TabArrow size={16} down />}
      prefix={<Timeline size={16} />}
    />
  );
};

export default RfpSubmitForm;
