import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RfpStatusFlowSteps } from '.';
import { CancelRfpFlowSteps, ChoosePartnerFlowSteps, } from '../../../features/entities';
import useMediaQuery from '../../../features/helpers/use-media-query';
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTheme } from '../../../plugins/styled';
import Button from '../../common/button';
import Div from '../../common/div';
import Typography from '../../common/typography';
const StatusFlowFooter = ({ step, handleChangeFlow, cancelFlowStep, choosePartnerFlowStep, hasShortlistMatches, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    const isCancel = [RfpStatusFlowSteps.CancelRfpFlow].includes(step);
    const isNoPartnerSelected = [
        RfpStatusFlowSteps.NoPartnerSelectedOptions,
    ].includes(step);
    const isPartnerSelected = [RfpStatusFlowSteps.PartnerChooseSelected].includes(step);
    const isTablet = useMediaQuery({ maxWidth: 559 });
    const showCancel = !isCancel && choosePartnerFlowStep === ChoosePartnerFlowSteps.chosenSpStep;
    const showChoosePartner = !isPartnerSelected &&
        hasShortlistMatches &&
        cancelFlowStep === CancelRfpFlowSteps.cancelFormStep;
    if (isNoPartnerSelected || (!showChoosePartner && !showCancel))
        return _jsx(_Fragment, {});
    return (_jsxs(Div, { flex: "row", align: "center", justify: "space-between", tablet: { flex: 'column', align: 'center', gap: '1' }, children: [isTablet && (_jsx(Typography, { bold: true, elementTheme: "overline", style: { letterSpacing: 0.3 }, textTransform: "uppercase", color: theme.colors.purple_3, children: t('projectStatusFlow|psf_footer_wrong_status') })), _jsx(Div, { fitContent: true, children: showCancel && (_jsx(Button, { onClick: () => handleChangeFlow(RfpStatusFlowSteps.CancelRfpFlow), isLink: true, children: t('projectStatusFlow|psf_footer_underlink3') })) }), _jsx(Div, { fitContent: true, children: showChoosePartner && (_jsx(Button, { onClick: () => handleChangeFlow(RfpStatusFlowSteps.PartnerChooseSelected), isLink: true, children: t('projectStatusFlow|psf_footer_underlink2') })) })] }));
};
export default StatusFlowFooter;
