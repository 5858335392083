import {
  Company,
  CompanyInitialState,
  Contact,
  ContactInitialState,
} from '@hellodarwin/core/lib/features/entities';
import {
  Profile,
  ProfileInitialState,
} from '@hellodarwin/core/lib/features/entities/profile-entities';
import CoreFeatured from '@hellodarwin/core/lib/features/featured-flags';
import useDebounceFunction from '@hellodarwin/core/lib/features/hooks/use-debounce-function';
import { CompanyInfosProvider } from '@hellodarwin/core/lib/features/providers/company-infos-provider';
import { FeatureFlagProvider } from '@hellodarwin/core/lib/features/providers/featured-flags-provider';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useLogin } from '@hellodarwin/core/lib/features/providers/login-provider';
import useUTM from '@hellodarwin/core/lib/features/providers/utm-provider';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import ClientFeatured from '../../featured';
import { selectContactById } from '../api/slices/global-slice';
import { me, selectCompany, selectProfile } from '../api/slices/profile-slice';
import { useClientApi } from '../api/use-client-api';

export interface UserContextType {
  profile: Profile;
  isLoggedIn: boolean;
  company: Company;
  contact: Contact;
}

export const UserContext = React.createContext<UserContextType>({
  profile: ProfileInitialState,
  isLoggedIn: false,
  company: CompanyInitialState,
  contact: ContactInitialState,
});

interface UserProviderProps extends PropsWithChildren {}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const api = useClientApi();
  const dispatch = useAppDispatch();
  const utmSource = useUTM();

  const { accessToken } = useLogin();

  const profile = useAppSelector(selectProfile);
  const company = useAppSelector(selectCompany);
  const contact = useAppSelector((state) =>
    selectContactById(state, profile.contact_id),
  );
  const isLoggedIn = useMemo(() => !!profile.company_id?.length, [profile]);

  const { changeLanguage } = useLocale();

  const fetchClient = useDebounceFunction(() => {
    dispatch(me({ api, utm: utmSource }));
  }, 300);

  useEffect(() => {
    if (accessToken === '') return;
    fetchClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (isLoggedIn && !!profile.contact_preferred_language) {
      changeLanguage(profile.contact_preferred_language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (profile?.contact_email) {
      try {
        var _hsq = (window._hsq = window._hsq || []);
        _hsq?.push([
          'identify',
          {
            email: profile.contact_email,
          },
        ]);
      } catch (e: any) {
        console.error(e);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.contact_email]);

  return (
    <UserContext.Provider value={{ profile, isLoggedIn, company, contact }}>
      <CompanyInfosProvider
        profile={profile}
        company={company}
        contacts={[contact]}
      >
        <FeatureFlagProvider flags={{ ...CoreFeatured, ...ClientFeatured }}>
          {children}
        </FeatureFlagProvider>
      </CompanyInfosProvider>
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

export default UserProvider;
