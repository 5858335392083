import { AdminProjectResponse } from '@hellodarwin/core/lib/features/entities/projects-entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useMemo } from 'react';
import { BusinessPrioritySingleSectionProps } from '..';
import ChildProjectsSection from './child-projects-section';

export enum BusinessPrioritySingleSections {
  Projects = 'projects',
  Applications = 'applications',
}

export interface BusinessPrioritySingleSectionMapping {
  [key: string]: BusinessPrioritySingleSectionProps;
}

export interface BusinessPrioritySectionBlockProps {
  priority: AdminProjectResponse;
}

const useBusinessPriorityBlocks = (
  props: BusinessPrioritySectionBlockProps,
): BusinessPrioritySingleSectionMapping => {
  const { t } = useTranslations();

  return useMemo(() => {
    const blocks: BusinessPrioritySingleSectionMapping = {
      [BusinessPrioritySingleSections.Projects]: {
        label: t('project|childProjects'),
        children: <ChildProjectsSection {...props} />,
      },
    };
    return blocks;
  }, [props, t]);
};

export default useBusinessPriorityBlocks;
