import Button from '@hellodarwin/core/lib/components/common/button';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';

import { useAppDispatch } from '../../app/index';
import { toggleRfpsModal } from '../../features/api/slices/rfp-slice';

const RfpSubmissionRow = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const handleRfpSubmitModal = () => {
    dispatch(toggleRfpsModal({ isVisible: true, type: 'rfpSubmit' }));
  };

  return (
    <Button
      defaultStyle={theme.colors.yellow_1}
      size="small"
      onClick={handleRfpSubmitModal}
    >
      {t('rfp|rfpActionCard.submitAnRfp.title')}
    </Button>
  );
};

export default RfpSubmissionRow;
