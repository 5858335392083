import { PropsWithChildren } from 'react';
import BusinessPrioritySingleSectionHeader from './business-priority-single-section-header';
import { BusinessPrioritySingleSectionContainer } from './styles';

export interface BusinessPrioritySingleSectionProps extends PropsWithChildren {
  label: string;
}
const BusinessPrioritySingleSection: React.FC<
  BusinessPrioritySingleSectionProps
> = (props) => {
  return (
    <BusinessPrioritySingleSectionContainer>
      <BusinessPrioritySingleSectionHeader {...props} />
      {!!props.children && <div>{props.children}</div>}
    </BusinessPrioritySingleSectionContainer>
  );
};

export default BusinessPrioritySingleSection;
