import Typography from '@hellodarwin/core/lib/components/common/typography';
import { BusinessPrioritySingleSectionProps } from '.';
import { BusinessPrioritySingleSectionHeaderContainer } from './styles';

const BusinessPrioritySingleSectionHeader: React.FC<
  BusinessPrioritySingleSectionProps
> = ({ label }) => {
  return (
    <BusinessPrioritySingleSectionHeaderContainer>
      <Typography.Title level={2} elementTheme="h6">
        {label}
      </Typography.Title>
    </BusinessPrioritySingleSectionHeaderContainer>
  );
};

export default BusinessPrioritySingleSectionHeader;
