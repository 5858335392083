import Typography from '@hellodarwin/core/lib/components/common/typography';
import { ProjectSingleSectionProps } from '.';
import { ProjectSingleSectionHeaderContainer } from './styles';

const ProjectSingleSectionHeader: React.FC<ProjectSingleSectionProps> = ({
  label,
}) => {
  return (
    <ProjectSingleSectionHeaderContainer>
      <Typography.Title level={2} elementTheme="h6">
        {label}
      </Typography.Title>
    </ProjectSingleSectionHeaderContainer>
  );
};

export default ProjectSingleSectionHeader;
