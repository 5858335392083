import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { ElementTheme } from '@hellodarwin/core/lib/components/common/typography/entities';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';
import { ReactNode, useMemo } from 'react';

export type PriorityTileInfoSize = 'small' | 'medium';
export interface PriorityTileInfoProps {
  Icon: IconNode;
  value: ReactNode;
  size?: PriorityTileInfoSize;
  highlight?: boolean;
}

const PriorityTileInfo: React.FC<PriorityTileInfoProps> = ({
  Icon,
  value,
  size,
  highlight,
}) => {
  const theme = useTheme();
  const { iconSize, valueSize } = useMemo(() => {
    const iconSize = size === 'small' ? 16 : 20;
    const valueSize: ElementTheme = size === 'small' ? 'body3' : 'body2';
    return { iconSize, valueSize };
  }, [size]);

  return (
    <Div
      flex="row"
      gap={8}
      align="center"
      fitContent
      style={{ color: highlight ? theme.colors.purple_2 : theme.colors.grey_2 }}
    >
      <Icon size={iconSize} />
      <Typography elementTheme={valueSize}>{value}</Typography>
    </Div>
  );
};

export default PriorityTileInfo;
