var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTheme } from 'styled-components';
import Modal from '..';
import parse from '../../../../features/helpers/parse';
import { useTranslation } from '../../../../plugins/i18n';
import HubspotMeeting from '../../../hubspot/hubspot-meeting';
import Button from '../../button';
import Div from '../../div';
import Typography from '../../typography';
const ModalLayoutWithMeeting = (_a) => {
    var { title, description, Icon, iconSize, iconColor, meetingUrl, app, email, phone, name, companyName, additionalParams } = _a, modalProps = __rest(_a, ["title", "description", "Icon", "iconSize", "iconColor", "meetingUrl", "app", "email", "phone", "name", "companyName", "additionalParams"]);
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = classNames('hd-modal-with-meeting');
    const hubspotMeetingProps = {
        meetingUrl,
        email,
        app,
        phone,
        name,
        companyName,
        additionalParams,
    };
    return (_jsx(Modal, Object.assign({}, modalProps, { size: "large", className: classes, children: _jsxs(Div, { flex: "column", style: { margin: '0 auto' }, gap: 32, fitMaxContent: true, children: [_jsxs(Div, { flex: "row", gap: 54, fitContent: true, children: [_jsxs(Div, { flex: "column", gap: 20, style: { marginTop: 108, maxWidth: 440 }, children: [_jsx(Icon, { width: iconSize, height: iconSize, style: { color: iconColor } }), _jsx(Typography.Title, { level: 3, children: title }), description && (_jsx(Typography, { elementTheme: "body2", children: parse(description) }))] }), _jsx("div", { style: { flexShrink: 0 }, children: _jsx(HubspotMeeting, Object.assign({}, hubspotMeetingProps)) })] }), _jsx(Div, { flex: "column", align: "flex-end", children: _jsx(Button, { size: "small", style: { flex: 1 }, defaultStyle: theme.colors.primary, onClick: modalProps.handleCancel, children: t('button.cancel') }) })] }) })));
};
export default ModalLayoutWithMeeting;
