import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
import { useTranslation } from '../plugins/i18n';
export var OnBoardingSteps;
(function (OnBoardingSteps) {
    OnBoardingSteps[OnBoardingSteps["CompanyName"] = 0] = "CompanyName";
    OnBoardingSteps[OnBoardingSteps["Company"] = 1] = "Company";
    OnBoardingSteps[OnBoardingSteps["Contact"] = 2] = "Contact";
    OnBoardingSteps[OnBoardingSteps["Category"] = 3] = "Category";
    OnBoardingSteps[OnBoardingSteps["Services"] = 4] = "Services";
    OnBoardingSteps[OnBoardingSteps["Specialties"] = 5] = "Specialties";
    OnBoardingSteps[OnBoardingSteps["Submit"] = 6] = "Submit";
    OnBoardingSteps[OnBoardingSteps["CompanyDetails"] = 7] = "CompanyDetails";
})(OnBoardingSteps || (OnBoardingSteps = {}));
export var OnBoardingStepTypes;
(function (OnBoardingStepTypes) {
    OnBoardingStepTypes[OnBoardingStepTypes["Partner"] = 0] = "Partner";
    OnBoardingStepTypes[OnBoardingStepTypes["Client"] = 1] = "Client";
})(OnBoardingStepTypes || (OnBoardingStepTypes = {}));
export const OnBoardingContext = createContext({
    currentStep: OnBoardingSteps.CompanyName,
    setCurrentStep: () => null,
    progressSteps: [],
});
const OnBoardingProvider = ({ stepType, children, }) => {
    const { t } = useTranslation();
    const progressStepsMap = {
        [OnBoardingStepTypes.Partner]: [
            {
                step: OnBoardingSteps.CompanyName,
                label: t('onboarding|sider.steps.1'),
            },
            { step: OnBoardingSteps.Company, label: t('onboarding|sider.steps.2') },
            { step: OnBoardingSteps.Contact, label: t('onboarding|sider.steps.3') },
            { step: OnBoardingSteps.Category, label: t('onboarding|sider.steps.4') },
            { step: OnBoardingSteps.Services, label: t('onboarding|sider.steps.5') },
            {
                step: OnBoardingSteps.Specialties,
                label: t('onboarding|sider.steps.6'),
            },
            { step: OnBoardingSteps.Submit, label: t('onboarding|sider.steps.7') },
        ],
        [OnBoardingStepTypes.Client]: [],
    };
    const [step, setStep] = useState(stepType === OnBoardingStepTypes.Partner
        ? OnBoardingSteps.CompanyName
        : OnBoardingSteps.CompanyDetails);
    const value = {
        currentStep: step,
        setCurrentStep: setStep,
        progressSteps: progressStepsMap[stepType],
    };
    return (_jsx(OnBoardingContext.Provider, { value: value, children: children }));
};
export default OnBoardingProvider;
export const useOnBoarding = () => useContext(OnBoardingContext);
