import {
  ApiError,
  ApiErrorInitialState,
} from '@hellodarwin/core/lib/features/entities/api-entitites';
import {
  AdminProjectResponse,
  CreateProjectFormResponse,
  InitialProjectState,
  PreviewProjectResponse,
  ProjectGrant,
  ProjectProgram,
  ProjectProgramWithGrant,
} from '@hellodarwin/core/lib/features/entities/projects-entities';
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app/app-store';
import ClientApiV2 from '../client-api-v2';
import SliceRequest from '../slice-request';

type StatusType =
  | 'generic'
  | 'singleProjects'
  | 'allProjects'
  | 'assignToProject'
  | 'createProject'
  | 'updateProject'
  | 'fetchProjectPrograms';
type Status = {
  [key in StatusType]: 'idle' | 'pending';
};

const projectsAdapter = createEntityAdapter({
  selectId: (model: AdminProjectResponse) => model.project_id,
});

const projectProgramsAdapter = createEntityAdapter({
  selectId: (model: ProjectProgramWithGrant) => model.program_id,
});

const projectPreviewAdapter = createEntityAdapter({
  selectId: (model: PreviewProjectResponse) => model.project_id,
});

export interface ProjectsState {
  status: Status;
  error: ApiError;
  projects: EntityState<AdminProjectResponse, string>;
  projects_preview: EntityState<PreviewProjectResponse, string>;
  projects_programs: {
    [projectId: string]: EntityState<ProjectProgramWithGrant, string>;
  };
}

const initialState: ProjectsState = {
  status: {
    generic: 'idle',
    singleProjects: 'idle',
    allProjects: 'idle',
    createProject: 'idle',
    assignToProject: 'idle',
    updateProject: 'idle',
    fetchProjectPrograms: 'idle',
  },
  error: ApiErrorInitialState,
  projects: projectsAdapter.getInitialState(),
  projects_preview: projectPreviewAdapter.getInitialState(),
  projects_programs: {},
};

export const fetchSingleProject = SliceRequest<
  AdminProjectResponse,
  { api: ClientApiV2; project_id: string; locale: string }
>('fetchSingleProject', async ({ api, project_id, locale }) => {
  const response = await api.get<AdminProjectResponse>(
    `/projects/${project_id}?locale=${locale}`,
  );
  return response.data;
});

export const fetchAllProjects = SliceRequest<
  AdminProjectResponse[],
  { api: ClientApiV2 }
>('fetchAllProjects', async ({ api }) => {
  const response = await api.get<AdminProjectResponse[]>(`/projects`);
  return response.data;
});

export const fetchAllProjectsPreview = SliceRequest<
  PreviewProjectResponse[],
  { api: ClientApiV2 }
>('fetchAllProjectsPreview', async ({ api }) => {
  const response = await api.get<PreviewProjectResponse[]>(`/projects/preview`);
  return response.data;
});

export const createProject = SliceRequest<
  AdminProjectResponse,
  { api: ClientApiV2; data: CreateProjectFormResponse }
>('createProject', async ({ api, data }) => {
  const response = await api.post<AdminProjectResponse>(`/projects`, data);
  return response.data;
});

export const assignGrantToProject = SliceRequest<
  ProjectGrant,
  { api: ClientApiV2; grantId: string; projectId: string }
>('assignGrantToProject', async ({ api, grantId, projectId }) => {
  const response = await api.post<ProjectGrant>(
    `/projects/grant/${projectId}?grantId=${grantId}`,
    {},
  );
  return response.data;
});

export const assignApplicationToProject = SliceRequest<
  ProjectProgram,
  { api: ClientApiV2; applicationId: string; projectId: string }
>('assignApplicationToProject', async ({ api, applicationId, projectId }) => {
  const response = await api.post<ProjectProgram>(
    `/projects/program/${projectId}?programId=${applicationId}`,
    {},
  );
  return response.data;
});

export const updateProject = SliceRequest<
  AdminProjectResponse,
  { api: ClientApiV2; data: AdminProjectResponse }
>('updateProject', async ({ api, data }) => {
  const response = await api.put<AdminProjectResponse>(`/projects`, data);
  return response.data;
});

export const fetchProjectPrograms = SliceRequest<
  ProjectProgramWithGrant[],
  { api: ClientApiV2; projectId: string; locale: string }
>('fetchProjectPrograms', async ({ api, projectId, locale }) => {
  const response = await api.get<ProjectProgramWithGrant[]>(
    `/projects/${projectId}/programs?locale=${locale}`,
  );
  return response.data;
});

const projectsSlice = createSlice({
  name: 'Projects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSingleProject.pending, (state) => {
      state.status.singleProjects = 'pending';
    });
    builder.addCase(fetchSingleProject.fulfilled, (state, { payload }) => {
      state.projects = projectsAdapter.upsertOne(state.projects, payload);
      state.status.singleProjects = 'idle';
    });
    builder.addCase(fetchSingleProject.rejected, (state, { payload }) => {
      state.status.singleProjects = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(fetchAllProjects.pending, (state) => {
      state.status.allProjects = 'pending';
    });
    builder.addCase(fetchAllProjects.fulfilled, (state, { payload }) => {
      projectsAdapter.setAll(state.projects, payload);
      state.status.allProjects = 'idle';
    });
    builder.addCase(fetchAllProjects.rejected, (state, { payload }) => {
      state.status.allProjects = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(fetchAllProjectsPreview.pending, (state) => {
      state.status.allProjects = 'pending';
    });
    builder.addCase(fetchAllProjectsPreview.fulfilled, (state, { payload }) => {
      projectPreviewAdapter.setAll(state.projects_preview, payload);
      state.status.allProjects = 'idle';
    });
    builder.addCase(fetchAllProjectsPreview.rejected, (state, { payload }) => {
      state.status.allProjects = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });

    builder.addCase(createProject.pending, (state) => {
      state.status.createProject = 'pending';
    });
    builder.addCase(createProject.fulfilled, (state, { payload }) => {
      if (!!payload.parent_project?.length) {
        const parent = state.projects.entities[payload.parent_project];
        projectsAdapter.updateOne(state.projects, {
          id: payload.parent_project,
          changes: {
            projects: [...(parent.projects ?? []), payload],
          },
        });
      } else {
        projectsAdapter.addOne(state.projects, payload);
      }
      state.status.createProject = 'idle';
    });
    builder.addCase(createProject.rejected, (state, { payload }) => {
      state.status.createProject = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(assignGrantToProject.pending, (state) => {
      state.status.assignToProject = 'pending';
    });
    builder.addCase(assignGrantToProject.fulfilled, (state, { payload }) => {
      const parentProject = Object.values(state.projects.entities).find(
        (p) => !!p.projects?.find((p2) => p2.project_id === payload.project_id),
      );
      if (!!parentProject) {
        projectsAdapter.updateOne(state.projects, {
          id: parentProject.project_id,
          changes: {
            projects: parentProject.projects?.map((p) =>
              p.project_id === payload.project_id
                ? { ...p, grants: [...(p.grants ?? []), payload] }
                : p,
            ),
          },
        });
      }
      state.status.assignToProject = 'idle';
    });
    builder.addCase(assignGrantToProject.rejected, (state, { payload }) => {
      state.status.assignToProject = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(assignApplicationToProject.pending, (state) => {
      state.status.assignToProject = 'pending';
    });
    builder.addCase(
      assignApplicationToProject.fulfilled,
      (state, { payload }) => {
        const parentProject = Object.values(state.projects.entities).find(
          (project) =>
            project.projects?.some(
              (subProject) => subProject.project_id === payload.project_id,
            ),
        );

        if (parentProject) {
          const updatedProjects = parentProject.projects?.map((subProject) => {
            if (subProject.project_id === payload.project_id) {
              const updatedGrants = subProject.grants?.map((grant) =>
                grant.grant_id === payload.program_grant_id
                  ? {
                      ...grant,
                      programs: [...(grant.programs ?? []), payload],
                    }
                  : grant,
              );

              return {
                ...subProject,
                programs: [...(subProject.programs ?? []), payload],
                grants: updatedGrants,
              };
            }
            return subProject;
          });

          projectsAdapter.updateOne(state.projects, {
            id: parentProject.project_id,
            changes: { projects: updatedProjects },
          });
        }

        state.status.assignToProject = 'idle';
      },
    );

    builder.addCase(
      assignApplicationToProject.rejected,
      (state, { payload }) => {
        state.status.assignToProject = 'idle';
        state.error = payload ?? ApiErrorInitialState;
      },
    );
    builder.addCase(updateProject.pending, (state) => {
      state.status.updateProject = 'pending';
    });
    builder.addCase(updateProject.fulfilled, (state, { payload }) => {
      if (!!payload.parent_project?.length) {
        const parent = state.projects.entities[payload.parent_project];
        projectsAdapter.updateOne(state.projects, {
          id: payload.parent_project,
          changes: {
            projects: parent.projects?.map((p) =>
              p.project_id === payload.project_id ? payload : p,
            ),
          },
        });
      } else {
        projectsAdapter.updateOne(state.projects, {
          id: payload.project_id,
          changes: payload,
        });
      }

      state.status.updateProject = 'idle';
    });
    builder.addCase(updateProject.rejected, (state, { payload }) => {
      state.status.updateProject = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(fetchProjectPrograms.pending, (state) => {
      state.status.fetchProjectPrograms = 'pending';
    });
    builder.addCase(
      fetchProjectPrograms.fulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { projectId },
          },
        },
      ) => {
        const programs = !!state.projects_programs[projectId]
          ? state.projects_programs[projectId]
          : projectProgramsAdapter.getInitialState();
        state.projects_programs[projectId] = projectProgramsAdapter.setAll(
          programs,
          payload,
        );
        state.status.fetchProjectPrograms = 'idle';
      },
    );
    builder.addCase(fetchProjectPrograms.rejected, (state, { payload }) => {
      state.status.fetchProjectPrograms = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
  },
});

export const selectProjectsIsLoading = createSelector(
  [
    (state: RootState, _?: StatusType) => state.projects.status,
    (_: RootState, type?: StatusType) => type,
  ],
  (status, type) => {
    if (!type) {
      return !!Object.keys(status).find((state) => state === 'pending');
    } else {
      return status[type] === 'pending';
    }
  },
);

export const selectProgramsByProjectId = createSelector(
  [(state, projectId: string) => state.projects.projects_programs?.[projectId]],
  (projectPrograms) => {
    if (!projectPrograms) {
      return [];
    }
    return projectProgramsAdapter.getSelectors().selectAll(projectPrograms);
  },
);

export const { selectAll: selectAllProjects, selectById: selectProjectById } =
  projectsAdapter.getSelectors((state: RootState) => state.projects.projects);
export const {
  selectAll: selectAllProjectsPreview,
  selectById: selectProjectPreviewById,
} = projectPreviewAdapter.getSelectors(
  (state: RootState) => state.projects.projects_preview,
);

export const selectChildProject = createSelector(
  (state, priorityId: string, projectId: string) =>
    selectProjectById(state, priorityId)?.projects?.find(
      (p) => p.project_id === projectId,
    ),
  (project: AdminProjectResponse | undefined) => project ?? InitialProjectState,
);
export const ProjectsHasError = (state: RootState) =>
  state.projects.error !== ApiErrorInitialState;

export const projectsReducer = projectsSlice.reducer;
