import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { ReviewFormSteps } from '../../../../../features/entities/rfp-status-flow-entities';
import ReviewForm from '../../../../reviews/review/review-form';
const QuickReviewStep = ({ handleClose, editionFunctions, provider, rfpId, matchId, companyTags, }) => {
    const handleReviewProvider = async (reviewRequest) => {
        if (!!editionFunctions.review) {
            editionFunctions.review(rfpId, provider.provider_id, reviewRequest, matchId);
        }
    };
    const [reviewStep, setReviewStep] = useState(ReviewFormSteps.Experience);
    return (_jsx(ReviewForm, { handleClose: handleClose, providerId: provider.provider_id, rfpId: rfpId, providerName: provider.name, reviewProvider: handleReviewProvider, setReviewStep: setReviewStep, reviewStep: reviewStep, tags: provider.tags, companyTags: companyTags !== null && companyTags !== void 0 ? companyTags : [] }));
};
export default QuickReviewStep;
