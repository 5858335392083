import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useMemo, useState, } from 'react';
import { useLocation } from './location-provider';
export const UTM_CACHE_KEY = 'utmParams';
export const InitialStateUTMParamsType = {};
export const UTMContext = createContext(InitialStateUTMParamsType);
export const UTMProvider = ({ children }) => {
    const { search } = useLocation();
    const [utmParams, setUtmParams] = useState({});
    useEffect(() => {
        const params = new URLSearchParams(search);
        setUtmParams((prevParams) => {
            var _a, _b, _c, _d, _e;
            return ({
                utmSource: (_a = params.get('utm_source')) !== null && _a !== void 0 ? _a : prevParams.utmSource,
                utmMedium: (_b = params.get('utm_medium')) !== null && _b !== void 0 ? _b : prevParams.utmMedium,
                utmCampaign: (_c = params.get('utm_campaign')) !== null && _c !== void 0 ? _c : prevParams.utmCampaign,
                utmTerm: (_d = params.get('utm_term')) !== null && _d !== void 0 ? _d : prevParams.utmTerm,
                utmContent: (_e = params.get('utm_content')) !== null && _e !== void 0 ? _e : prevParams.utmContent,
            });
        });
    }, [search]);
    const value = useMemo(() => utmParams, [utmParams]);
    return _jsx(UTMContext.Provider, { value: value, children: children });
};
export const useUTM = () => useContext(UTMContext);
export default useUTM;
