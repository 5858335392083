import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { CancelRfpFlowSteps, ChoosePartnerFlowSteps, } from '../../../features/entities/rfp-status-flow-entities';
import Div from '../../common/div';
import ModalLayoutSimple from '../../common/hd-modal/layouts/modal-simple';
import CancelRfpFlow from './cancel-rfp-flow';
import ChooseRfpPartnerFlow from './choose-partner-flow';
import NoPartnerSelectedFlow from './no-partner-selected-flow';
import StatusFlowFooter from './status-flow-footer';
import { StatusFlowContainer, StatusFlowContent } from './styles';
export var RfpStatusFlowSteps;
(function (RfpStatusFlowSteps) {
    RfpStatusFlowSteps["NoPartnerSelectedOptions"] = "noPartnerSelectedOptions";
    RfpStatusFlowSteps["PartnerChooseSelected"] = "partnerChooseSelected";
    RfpStatusFlowSteps["CancelRfpFlow"] = "Cancel Rfp Flow";
})(RfpStatusFlowSteps || (RfpStatusFlowSteps = {}));
const FlowContent = ({ editionFunctions, rfp, step, cancelFlowStep, setCancelFlowStep, choosePartnerFlowStep, setChoosePartnerFlowStep, handleClose, shortlistMatches, isModal, }) => {
    return (_jsx(StatusFlowContent, { className: "fade-in", "$isModal": isModal, style: { width: '100%' }, children: step === RfpStatusFlowSteps.CancelRfpFlow ? (_jsx(CancelRfpFlow, { handleClose: handleClose, editionFunctions: editionFunctions, rfp: rfp, flowStep: cancelFlowStep, setFlowStep: setCancelFlowStep })) : step === RfpStatusFlowSteps.NoPartnerSelectedOptions ? (_jsx(NoPartnerSelectedFlow, { handleClose: handleClose })) : (step === RfpStatusFlowSteps.PartnerChooseSelected && (_jsx(ChooseRfpPartnerFlow, { handleClose: handleClose, editionFunctions: editionFunctions, rfp: rfp, shortlistMatches: shortlistMatches, flowStep: choosePartnerFlowStep, setFlowStep: setChoosePartnerFlowStep }))) }));
};
const RfpStatusFlow = (props) => {
    const { initialStep, rfp, shortlistMatches, modalOpen, handleChangeFlow, isModal, handleClose, } = props;
    const [editableRfp, setEditableRfp] = useState(rfp);
    const [step, setStep] = useState(initialStep || RfpStatusFlowSteps.PartnerChooseSelected);
    const [choosePartnerFlowStep, setChoosePartnerFlowStep] = useState(ChoosePartnerFlowSteps.chosenSpStep);
    const [cancelFlowStep, setCancelFlowStep] = useState(CancelRfpFlowSteps.cancelFormStep);
    useEffect(() => {
        if (!!initialStep) {
            setStep(initialStep);
        }
    }, [initialStep]);
    useEffect(() => {
        setCancelFlowStep(CancelRfpFlowSteps.cancelFormStep);
        setChoosePartnerFlowStep(ChoosePartnerFlowSteps.chosenSpStep);
    }, [step]);
    useEffect(() => {
        if (!!rfp.rfp_id)
            setEditableRfp(rfp);
    }, [rfp]);
    if (!editableRfp.rfp_id)
        return _jsx(_Fragment, {});
    return isModal ? (_jsx(ModalLayoutSimple, { open: !!modalOpen, handleCancel: handleClose, size: step === RfpStatusFlowSteps.PartnerChooseSelected ? 'large' : 'medium', children: _jsxs(Div, { flex: "column", gap: 54, children: [_jsx(FlowContent, Object.assign({}, props, { rfp: editableRfp, step: step, cancelFlowStep: cancelFlowStep, setCancelFlowStep: setCancelFlowStep, choosePartnerFlowStep: choosePartnerFlowStep, setChoosePartnerFlowStep: setChoosePartnerFlowStep })), _jsx(StatusFlowFooter, { step: step, handleChangeFlow: handleChangeFlow, cancelFlowStep: cancelFlowStep, choosePartnerFlowStep: choosePartnerFlowStep, hasShortlistMatches: !!shortlistMatches.length })] }) })) : (_jsxs(StatusFlowContainer, { "$size": step === RfpStatusFlowSteps.NoPartnerSelectedOptions
            ? 'large'
            : 'medium', children: [_jsx(FlowContent, Object.assign({}, props, { rfp: editableRfp, step: step, cancelFlowStep: cancelFlowStep, setCancelFlowStep: setCancelFlowStep, choosePartnerFlowStep: choosePartnerFlowStep, setChoosePartnerFlowStep: setChoosePartnerFlowStep })), _jsx(StatusFlowFooter, { step: step, handleChangeFlow: handleChangeFlow, cancelFlowStep: cancelFlowStep, choosePartnerFlowStep: choosePartnerFlowStep, hasShortlistMatches: !!shortlistMatches.length })] }));
};
export default RfpStatusFlow;
export { default as RfpStatusFlowModal } from './rfp-status-flow-modal';
