import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/app-hooks';
import ProjectSingle from '../../../../components/projects/project-single';
import { ProjectSingleSections } from '../../../../components/projects/project-single/project-single-section/sections';
import {
  fetchSingleProject,
  selectChildProject,
  selectProjectById,
} from '../../../../features/api/slices/projects-slice';
import { useNewClientApi } from '../../../../features/api/use-client-api';

const ClientSingleProjectPage = () => {
  const { t } = useTranslation();
  const { projectId, priorityId } = useParams<{
    projectId: string;
    priorityId: string;
  }>();
  const { selectedLocale } = useLocale();
  const api = useNewClientApi();
  const dispatch = useAppDispatch();

  const priority = useAppSelector((state) =>
    selectProjectById(state, priorityId ?? ''),
  );
  const project = useAppSelector((state) =>
    selectChildProject(state, priorityId ?? '', projectId ?? ''),
  );

  useEffect(() => {
    dispatch(
      fetchSingleProject({
        api,
        project_id: priorityId ?? '',
        locale: selectedLocale,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priorityId]);

  return (
    <PageLayout
      app="client"
      goBackTo={`/game-plan/${priority?.project_id}`}
      title={project?.title ?? ''}
      breadcrumbs={[
        {
          breadcrumbName: t('navigation.home'),
          path: '/',
        },
        {
          breadcrumbName: t('project|gamePlan'),
          path: '/game-plan',
        },
        {
          breadcrumbName: priority?.title,
          path: `/game-plan/${priority?.project_id}`,
        },
        {
          breadcrumbName: project?.title,
        },
      ]}
    >
      {!!project && (
        <ProjectSingle
          project={project}
          priority={priority}
          sections={[ProjectSingleSections.Applications]}
        />
      )}
    </PageLayout>
  );
};

export default ClientSingleProjectPage;
