import AdminCard from '@hellodarwin/core/lib/components/admin/admin-card';
import Div from '@hellodarwin/core/lib/components/common/div';
import { AdminProjectResponse } from '@hellodarwin/core/lib/features/entities/projects-entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/index';
import {
  fetchAdmin,
  selectAdminById,
} from '../../../features/api/slices/global-slice';
import { useClientApi } from '../../../features/api/use-client-api';
import ProjectSingleHeader from './project-single-header';
import ProjectSingleSection from './project-single-section';
import useProjectBlocks, {
  ProjectSingleSections,
} from './project-single-section/sections';

export interface ProjectSingleProps {
  project: AdminProjectResponse;
  priority: AdminProjectResponse;
  sections: ProjectSingleSections[];
}

const ProjectSingle: React.FC<ProjectSingleProps> = (props) => {
  const api = useClientApi();
  const dispatch = useAppDispatch();
  const { t } = useTranslations();

  const { project, sections } = props;

  const admin = useAppSelector((state) =>
    selectAdminById(state, project?.admin_id ?? ''),
  );

  const blocks = useProjectBlocks({ project });

  useEffect(() => {
    if (!!project?.admin_id) {
      dispatch(fetchAdmin({ api, adminId: project.admin_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.admin_id]);

  return (
    <Div flex="column" gap={16}>
      <ProjectSingleHeader {...props} />
      {sections
        .filter((s) => !!blocks[s])
        .map((s) => (
          <ProjectSingleSection {...blocks[s]} />
        ))}
      {!!admin && (
        <AdminCard
          admin={admin}
          title={t('adminCard|adminCardGenericTitle')}
          subtitle={t('adminCard|adminCardGenericSubtitle')}
          fullWidth
        />
      )}
    </Div>
  );
};

export default ProjectSingle;
