import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import ContactAdvisorModal from '@hellodarwin/core/lib/components/programs/single/contact-advisor-modal';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Empty from 'antd/es/empty';
import { useState } from 'react';

export interface EmptyPrioritiesProps {}

const EmptyPriorities: React.FC<EmptyPrioritiesProps> = () => {
  const theme = useTheme();
  const { t } = useTranslations();

  const [contactAdvisorModalOpen, setContactAdvisorModalOpen] = useState(false);
  const handlecontactAdvisorModal = () =>
    setContactAdvisorModalOpen(!contactAdvisorModalOpen);

  return (
    <Div flex="column" gap={8} align="center" style={{ paddingBlock: 54 }}>
      <Empty
        description={
          <>
            <Typography elementTheme="subtitle2" color={theme.colors.grey_1}>
              {t('emptyProject|emptyProjectTitle')}
            </Typography>
            <Typography
              elementTheme="body2"
              color={theme.colors.grey_2}
              style={{ maxWidth: 540 }}
              textAlign="center"
            >
              {t('emptyProject|emptyProjectSubtitle')}
            </Typography>
          </>
        }
      />

      <Button
        defaultStyle={theme.colors.yellow_1}
        tablet={{ fullWidth: true }}
        onClick={handlecontactAdvisorModal}
        style={{ marginTop: 12 }}
        size="medium"
      >
        {t('emptyProject|emptyProjectCta')}
      </Button>
      <ContactAdvisorModal
        open={contactAdvisorModalOpen}
        handleCancel={handlecontactAdvisorModal}
      />
    </Div>
  );
};

export default EmptyPriorities;
