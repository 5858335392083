import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import {
  ApiError,
  ApiErrorInitialState,
  ContentfulFAQ,
  ContentfulResource,
} from '@hellodarwin/core/lib/features/entities';
import slugify from '@hellodarwin/core/lib/features/helpers/slugify';
import { SelectProps } from 'antd/es/select';
import { RootState } from '../../../app/index';
import showErrorNotification from '../../utils/show-error-notifications';
import ContentfulApi from '../client-contentful-api';
import SliceRequest from '../slice-request';

export interface ProjectsState {
  status: 'idle' | 'pending';
  error: ApiError;
  resources: EntityState<ContentfulResource, string>;
  previewResources: EntityState<ContentfulResource, string>;
  faq: EntityState<ContentfulFAQ, string>;
  tags: SelectProps['options'];
  selectedTag?: string;
}

const resourcesAdapter = createEntityAdapter({
  selectId: (model: ContentfulResource) => model?.slug ?? model?.sys?.id,
});
const faqAdapter = createEntityAdapter({
  selectId: (model: ContentfulFAQ) => model?.sys?.id || '',
});

const initialState: ProjectsState = {
  status: 'idle',
  error: ApiErrorInitialState,
  faq: faqAdapter.getInitialState(),
  resources: resourcesAdapter.getInitialState(),
  previewResources: resourcesAdapter.getInitialState(),
  tags: [],
  selectedTag: undefined,
};

export const getFAQ = createAsyncThunk<
  ContentfulFAQ[],
  { api: ContentfulApi },
  { rejectValue: ApiError }
>(
  'client/getFAQ',
  async ({ api }: { api: ContentfulApi }, { rejectWithValue }) => {
    try {
      return await api.fetchFAQ();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);
export const getResources = createAsyncThunk<
  ContentfulResource[],
  { api: ContentfulApi },
  { rejectValue: ApiError }
>(
  'client/getResources',
  async (
    {
      api,
    }: {
      api: ContentfulApi;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchResources();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBlogsByType = SliceRequest<
  ContentfulResource[],
  { api: ContentfulApi; type: string }
>('fetchAssetsFromOwner', async ({ api, type }) => {
  return await api.fetchBlogByType(type);
});

export const getResourcesWithLimit = createAsyncThunk<
  ContentfulResource[],
  { limit: number; api: ContentfulApi },
  { rejectValue: ApiError }
>(
  'client/getResourcesWithLimit',
  async (
    { limit, api }: { limit: number; api: ContentfulApi },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchResourcesWithLimit(limit);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);
export const getBlogWithLimit = createAsyncThunk<
  ContentfulResource[],
  { limit: number; api: ContentfulApi; type: string },
  { rejectValue: ApiError }
>(
  'client/getBlogWithLimit',
  async (
    { limit, api }: { limit: number; api: ContentfulApi; type: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchBlogWithLimit(limit);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const getResourceBySlug = SliceRequest<
  ContentfulResource,
  { api: ContentfulApi; slug: string }
>('getResourceBySlug', async ({ api, slug }) => {
  return await api.fetchResourceBySlug(slug);
});
export const getArticleById = SliceRequest<
  ContentfulResource,
  { api: ContentfulApi; id: string }
>('getArticleById', async ({ api, id }) => {
  return await api.fetchArticleById(id);
});

const resourcesSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setSelectedTag: (state, action) => {
      state.selectedTag = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getResources.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getResources.fulfilled, (state, { payload }) => {
      if (payload) {
        state.resources = resourcesAdapter.upsertMany(state.resources, payload);
      }
      state.status = 'idle';
    });
    builder.addCase(
      getBlogsByType.fulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { type },
          },
        },
      ) => {
        payload = payload.map((r) => ({
          ...r,
          tags: [type],
          slug: r.sys?.id ?? '',
        }));
        state.resources = resourcesAdapter.upsertMany(state.resources, payload);
        state.status = 'idle';
      },
    );
    builder.addCase(getBlogsByType.pending, (state, { payload }) => {
      state.status = 'pending';
    });
    builder.addCase(getFAQ.fulfilled, (state, { payload }) => {
      faqAdapter.setAll(state.faq, payload);
      state.status = 'idle';
    });
    builder.addCase(getFAQ.pending, (state, { payload }) => {
      state.status = 'pending';
    });
    builder.addCase(getFAQ.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(getResources.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(getResourcesWithLimit.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getResourcesWithLimit.fulfilled, (state, { payload }) => {
      if (payload) {
        resourcesAdapter.upsertMany(
          state.previewResources,
          payload.map((res) => ({
            ...res,
            date: res.date ?? res.sys?.createdAt,
          })),
        );
      }
      state.status = 'idle';
    });
    builder.addCase(getResourcesWithLimit.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(getBlogWithLimit.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getBlogWithLimit.fulfilled, (state, { payload }) => {
      if (payload) {
        resourcesAdapter.upsertMany(state.previewResources, payload);
      }

      state.status = 'idle';
    });
    builder.addCase(getBlogWithLimit.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(getResourceBySlug.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getResourceBySlug.fulfilled, (state, { payload }) => {
      state.resources = resourcesAdapter.upsertOne(state.resources, payload);
      state.status = 'idle';
    });
    builder.addCase(getResourceBySlug.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(getArticleById.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getArticleById.fulfilled, (state, { payload }) => {
      state.resources = resourcesAdapter.upsertOne(state.resources, payload);
      state.status = 'idle';
    });
    builder.addCase(getArticleById.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
  },
});

export const resourcesReducer = resourcesSlice.reducer;
export const { setSelectedTag } = resourcesSlice.actions;

export const { selectAll: selectAllResources, selectById: selectResourceById } =
  resourcesAdapter.getSelectors(
    (state: RootState) => state.resources.resources,
  );
export const { selectAll: selectAllPreviewResources } =
  resourcesAdapter.getSelectors(
    (state: RootState) => state.resources.previewResources,
  );
export const { selectAll: selectAllFAQs, selectById: selectFAQbyId } =
  faqAdapter.getSelectors((state: RootState) => state.resources.faq);

export const selectFilteredResources = createSelector<
  [
    (state: RootState) => ContentfulResource[],
    (state: RootState, selectedTag: string | undefined) => string | undefined,
    (
      state: RootState,
      selectedTag: string | undefined,
      currentPage: number,
    ) => number,
    (
      state: RootState,
      selectedTag: string | undefined,
      currentPage: number,
      pageSize: number,
    ) => number,
  ],
  { results: ContentfulResource[]; total: number }
>(
  [
    selectAllResources,
    (_, selectedTag) => selectedTag,
    (_, __, currentPage) => currentPage,
    (_, __, ___, pageSize) => pageSize,
  ],
  (resources, selectedTag, currentPage, pageSize) => {
    if (!resources.length || pageSize <= 0) return { total: 0, results: [] };

    const sortedResources = [...resources].sort((a, b) =>
      a.date && b.date ? b.date.localeCompare(a.date) : -1,
    );

    const filteredResources = selectedTag
      ? sortedResources.filter((resource) =>
          resource.tags?.some((tag) => slugify(tag) === selectedTag),
        )
      : sortedResources;

    const total = filteredResources.length;

    const startIndex = Math.max(0, (currentPage - 1) * pageSize);
    const endIndex = startIndex + pageSize;

    return { total, results: filteredResources.slice(startIndex, endIndex) };
  },
);

export const selectFilteredPreviewResources = createSelector(
  [selectAllPreviewResources, (_, limit?: number) => limit],
  (resources, limit = 3) => {
    return resources
      .sort((a, b) =>
        !a.date || !b.date ? -1 : b.date?.localeCompare(a?.date),
      )
      .slice(0, limit);
  },
);
export const selectResourcesLoading = (state: RootState) =>
  state.resources.status === 'pending';

export const selectResourcesTags = createSelector(
  selectAllResources,
  (resources) => {
    const options = Array.from(
      new Set(
        resources
          .flatMap((resource) => resource.tags)
          .filter((tag) => !!tag)
          .map((t) => t.trim()),
      ),
    ).reduce(
      (o, itm) => [
        ...(o ?? []),
        { key: slugify(itm), label: itm, value: slugify(itm) },
      ],
      [
        {
          key: '-',
          label: '-',
          value: '-',
        },
      ] as SelectProps['options'],
    );

    return options;
  },
);
