import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dollarFormat from '../../../features/helpers/dollar-format';
import useLocale from '../../../features/providers/locale-provider';
import { useTranslations } from '../../../features/providers/translations-provider';
const FundingAmount = ({ funding_max_amount, funding_min_amount, percentage_funding, }) => {
    const { t } = useTranslations();
    const { selectedLocale } = useLocale();
    return (_jsxs("ul", { children: [' ', !!funding_min_amount && !!funding_max_amount ? (_jsx("li", { children: t('grant_single|fromMinToMax', {
                    min: `${dollarFormat(funding_min_amount, selectedLocale)}`,
                    max: `${dollarFormat(funding_max_amount, selectedLocale)}`,
                }) })) : !!funding_min_amount ? (_jsx("li", { children: t('grant_single|minCount', {
                    count: `${dollarFormat(funding_min_amount, selectedLocale)}`,
                }) })) : (!!funding_max_amount && (_jsx("li", { children: t('grant_single|maxCount', {
                    count: `${dollarFormat(funding_max_amount, selectedLocale)}`,
                }) }))), !!percentage_funding && (_jsx("li", { children: t('grant_single|projectCostPercent', {
                    percent: `${percentage_funding}`,
                }) })), !funding_min_amount && !funding_max_amount && !percentage_funding && (_jsx("li", { children: t('grant_single|noCondition') }))] }));
};
export default FundingAmount;
