import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Div from '../../common/div';
import Typography from '../../common/typography';
const RfpStatusFlowLayout = ({ title, description, children, buttons, }) => {
    const Header = () => {
        return (_jsxs(Div, { flex: "column", gap: 12, align: "center", style: { maxWidth: 470 }, children: [!!title && (_jsx(Typography.Title, { level: 1, elementTheme: "h5", textAlign: "center", noMargin: true, children: title })), !!description && (_jsx(Typography, { textAlign: "center", children: description }))] }));
    };
    return (_jsxs(Div, { className: 'fade-in', style: { margin: '0 auto' }, flex: "column", gap: 32, align: "center", children: [_jsx(Header, {}), _jsxs(Div, { flex: "column", gap: 32, children: [_jsx(Div, { children: children }), buttons && (_jsx(Div, { flex: "row", gap: 16, justify: "center", tablet: { justify: 'stretch', flex: 'column', align: 'center' }, children: buttons }))] })] }));
};
export default RfpStatusFlowLayout;
