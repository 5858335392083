import { ModalProps } from '@hellodarwin/core/lib/components/common/hd-modal';
import ModalLayoutSimple from '@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-simple';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/app-hooks';
import {
  fetchTags,
  selectTags,
} from '../../../../features/api/slices/rfp-slice';
import { useClientApi } from '../../../../features/api/use-client-api';
import RfpSubmitForm from './rfp-submit-form';
import SubmitRfpSuccess from './submit-rfp-success';
type ClientSubmitRfpModalsProps = {
  open: boolean;
  handleClose: () => void;
};

export enum SubmitRfpFlowSteps {
  success = 'Success',
  form = 'Form',
}

const ClientSubmitRfpModal = ({
  open,
  handleClose,
}: ClientSubmitRfpModalsProps) => {
  const dispatch = useAppDispatch();
  const api = useClientApi();
  const tags = useAppSelector(selectTags);
  const [step, setStep] = useState<SubmitRfpFlowSteps>(SubmitRfpFlowSteps.form);
  const [modalSize, setModalSize] = useState<ModalProps['size']>('full');

  useEffect(() => {
    if (!open && tags && tags.length > 0) return;
    dispatch(fetchTags({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (step === SubmitRfpFlowSteps.success) setModalSize('small');
    if (step === SubmitRfpFlowSteps.form) setModalSize('medium');
  }, [step]);

  const handleModalClose = () => {
    setStep(SubmitRfpFlowSteps.form);
    handleClose();
    setModalSize('medium');
  };

  return (
    <ModalLayoutSimple
      open={open}
      handleCancel={handleModalClose}
      size={modalSize}
    >
      {step === SubmitRfpFlowSteps.form ? (
        <RfpSubmitForm
          closeModal={handleModalClose}
          openSuccesModal={() => setStep(SubmitRfpFlowSteps.success)}
          tags={tags}
        />
      ) : (
        step === SubmitRfpFlowSteps.success && (
          <SubmitRfpSuccess handleClose={handleModalClose} />
        )
      )}
    </ModalLayoutSimple>
  );
};

export default ClientSubmitRfpModal;
