import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form';
import React, { useState } from 'react';
import { PossibleCancelAnswers } from '../../../../../features/entities';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import RfpStatusFlowLayout from '../../rfp-status-flow-layout';
import ReasonCheckbox from './answer-checkbox';
const CancelReasonForm = ({ handleClose, handleCancelProject, }) => {
    const theme = useTheme();
    const { t } = useTranslations();
    const [selected, setSelected] = useState();
    const [otherReasonSpecified, setOtherReasonSpecified] = useState('');
    const handleChange = (name) => {
        setSelected(name);
    };
    const handleOtherReasonChange = (e) => {
        const { value } = e.target;
        setOtherReasonSpecified(value);
    };
    const onSubmit = () => {
        if (!!selected) {
            handleCancelProject(selected, otherReasonSpecified);
        }
    };
    return (_jsx(RfpStatusFlowLayout, { title: t(`projectStatusFlow|psf_cancel_header1`), description: t(`projectStatusFlow|psf_cancel_text1`), buttons: _jsxs(_Fragment, { children: [_jsx(Button, { defaultStyle: theme.colors.white_1, onClick: handleClose, tablet: { fullWidth: true }, size: "small", children: t('button|cancel') }), _jsx(Button, { onClick: onSubmit, tablet: { fullWidth: true }, size: "small", children: t('button|submit') })] }), children: _jsx(Form, { children: _jsx(Div, { flex: "column", gap: 16, children: React.Children.toArray(Object.keys(PossibleCancelAnswers).map((answer) => (_jsx(ReasonCheckbox, { answer: PossibleCancelAnswers[answer], selected: selected, handleChange: handleChange, handleOtherReasonChange: handleOtherReasonChange, t: t }, answer)))) }) }) }));
};
export default CancelReasonForm;
