import Typography from '@hellodarwin/core/lib/components/common/typography';
import parse from '@hellodarwin/core/lib/features/helpers/parse';
import { BusinessPrioritySingleProps } from '..';
import { BusinessPrioritySingleHeaderContainer } from './styles';

interface BusinessPrioritySingleHeaderProps
  extends BusinessPrioritySingleProps {}

const BusinessPrioritySingleHeader: React.FC<
  BusinessPrioritySingleHeaderProps
> = ({ priority }) => {
  return (
    <BusinessPrioritySingleHeaderContainer>
      <Typography.Title level={1} elementTheme="h5">
        {priority.title}
      </Typography.Title>
      {!!priority.description && (
        <Typography elementTheme="body1">
          {parse(priority.description)}
        </Typography>
      )}
    </BusinessPrioritySingleHeaderContainer>
  );
};

export default BusinessPrioritySingleHeader;
