import styled from 'styled-components';
const LOGO_WIDTH = 110;
export const ProjectProgramCardHeader = styled.div `
  background: ${({ theme }) => theme.colors.beige_2};
  padding: 32px 20px 24px 20px;
  display: flex;
  flex-direction: row;
  gap: 32px;
`;
export const ProjectProgramCardHeaderInfos = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 16px;
`;
export const ProjectProgramCardContainer = styled.div `
  border: 1px solid ${({ theme }) => theme.colors.grey_2};
  background: ${({ theme }) => theme.colors.beige_3};
  color: ${({ theme }) => theme.colors.grey_1};
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
  &:hover {
    box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};
    transform: translateY(-2px);
    cursor: pointer;
    color: ${({ theme }) => theme.colors.grey_1};
  }
`;
export const ProjectProgramCardBody = styled.div `
  border-top: 1px solid ${({ theme }) => theme.colors.grey_2};
`;
export const ProjectProgramCardBodyContent = styled.div `
  display: flex;
  margin: 0 auto;

  flex-direction: column;
  justify-content: flex-end;
  & > * {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey_2};

    &:last-child {
      border-bottom: none;
    }
  }
  ul {
    margin-block: 0;
    li {
      margin-block: 0;
    }
  }
`;
export const ProjectProgramCardBodyContentColumn = styled.div `
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  & > * {
    border-right: 1px solid ${({ theme }) => theme.colors.grey_2};

    &:last-child {
      border-right: none;
    }
  }
`;
