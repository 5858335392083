import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import ModalLayoutWithAvatar from '@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-avatar';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { unwrapResult } from '@reduxjs/toolkit';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';

import theme from '@hellodarwin/core/lib/theme';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/app-hooks';
import {
  closeMatchModal,
  selectShortlistMessage,
  setShortlistMessage,
} from '../../../features/api/slices/global-slice';
import {
  selectRfpMatchById,
  selectShortlistedMatches,
} from '../../../features/api/slices/matches-slice';
import {
  selectRfpById,
  sendShortlistMessage,
  setSelectedRfpId,
  toggleRfpsModal,
} from '../../../features/api/slices/rfp-slice';
import { useClientApi } from '../../../features/api/use-client-api';
import ErrorModal from './error-modal';
type ProviderMessageModalProps = {
  open: boolean;
  handleClose: () => void;
  matchId: string;
};

const ProviderMessageModal = ({
  open,
  handleClose,
  matchId,
}: ProviderMessageModalProps) => {
  const { t } = useTranslation();
  const api = useClientApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const match = useAppSelector((state) => selectRfpMatchById(state, matchId));
  const rfp = useAppSelector((state) =>
    selectRfpById(state, match?.rfp_id || ''),
  );

  const shortlistedMatches = useAppSelector(selectShortlistedMatches);

  const [error, setError] = useState<number>();
  const shortlistMessage = useAppSelector(selectShortlistMessage);

  const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    dispatch(setShortlistMessage(e.currentTarget.value));
  };

  const handleConfirm = () => {
    (async () => {
      await dispatch(
        sendShortlistMessage({
          api,
          matchId: matchId || '',
          shortlistMessage,
        }),
      )
        .then(unwrapResult)
        .catch((err: any) => {
          handleError(err.response.data.error_code);
        });

      if (rfp && shortlistedMatches.length < rfp.provider_max) {
        await dispatch(setSelectedRfpId(rfp.rfp_id));
        await dispatch(
          toggleRfpsModal({ isVisible: true, type: 'shortlistMore' }),
        );
      } else {
        navigate(0);
      }
      closeModal();
      handleClose();
    })();
  };

  const handleError = (errorCode: number) => {
    setError(errorCode);
  };

  const closeError = () => {
    setError(undefined);
  };

  const closeModal = () => {
    dispatch(closeMatchModal());
  };

  if (error)
    return <ErrorModal isVisible={true} error={error} onCancel={closeError} />;

  return (
    <ModalLayoutWithAvatar
      open={open}
      handleCancel={closeModal}
      size="medium"
      title={t('rfp|modal.shortlist.title', {
        companyName: match?.provider.name,
      })}
      avatarName={match.provider.name}
      avatarUrl={match.provider.logo}
      description={t('rfp|modal.shortlist.text', {
        companyName: match?.provider.name,
      })}
      Actions={
        <>
          <Button
            defaultStyle={theme.colors.white_1}
            onClick={handleClose}
            style={{ flex: 1 }}
            size="small"
          >
            {t('button.cancel')}
          </Button>
          <Button
            defaultStyle={theme.colors.purple_1}
            onClick={handleConfirm}
            style={{ flex: 1 }}
            disabled={shortlistMessage.trim() === ''}
            size="small"
          >
            {t(`rfp|modal.shortlist.confirm`)}
          </Button>
        </>
      }
    >
      <Div flex="column" gap={18}>
        <TextArea
          placeholder={t(`rfp|modal.shortlist.placeHolderText`)}
          rows={7}
          value={shortlistMessage}
          onChange={handleChange}
          maxLength={1000}
        />
      </Div>
    </ModalLayoutWithAvatar>
  );
};

export default ProviderMessageModal;
