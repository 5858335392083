import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ChoosePartnerFlowSteps, MatchInitialState, } from '../../../../features/entities';
import ChosenSp from './chosen-sp/index';
import RfpProgressStep from './progress';
import QuickReviewStep from './quick-review-flow';
const ChooseRfpPartnerFlow = ({ handleClose, editionFunctions, rfp, shortlistMatches, flowStep, setFlowStep, }) => {
    const [selectedMatch, setSelectedMatch] = useState(MatchInitialState);
    const [progress, setRfpProgress] = useState();
    useEffect(() => {
        if ((editionFunctions === null || editionFunctions === void 0 ? void 0 : editionFunctions.selectProgression) && !!progress) {
            editionFunctions.selectProgression(rfp.rfp_id, progress);
        }
    }, [progress]);
    return (_jsx(_Fragment, { children: flowStep === ChoosePartnerFlowSteps.chosenSpStep ? (_jsx(ChosenSp, { shortlistMatches: shortlistMatches, setStep: setFlowStep, selectedMatch: selectedMatch, editionFunctions: editionFunctions, setSelectedMatch: setSelectedMatch })) : flowStep === ChoosePartnerFlowSteps.progressStep ? (_jsx(RfpProgressStep, { setStep: setFlowStep, progress: progress, setRfpProgress: setRfpProgress, provider: selectedMatch.provider })) : flowStep === ChoosePartnerFlowSteps.QuickReviewStep ? (_jsx(QuickReviewStep, { handleClose: handleClose, provider: selectedMatch.provider, editionFunctions: editionFunctions, rfpId: rfp.rfp_id, matchId: selectedMatch.match_id })) : (_jsx(_Fragment, {})) }));
};
export default ChooseRfpPartnerFlow;
