import AppLayout from '@hellodarwin/core/lib/components/common/layout/app-layout';
import { useAppData } from '@hellodarwin/core/lib/features/providers/app-data-provider';
import AppRoutesRender from '@hellodarwin/core/lib/features/router/app-routes-render';
import { SegmentAnalytics } from '@segment/analytics.js-core';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from './app/index';
import ProviderActionModal from './components/provider/provider-action-modal';
import RfpActionModal from './components/rfp/rfp-action-modal';
import { selectProfileLoading } from './features/api/slices/profile-slice';
import BottomNavigation from './layout/bottom-navigation';
import Navigation from './layout/navigation';
import PageHeader from './layout/page-header';

import './App.scss';
import { useUser } from './features/providers/user-provider';
import useAppPages from './pages';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    _hsq: any;
  }
}

const ClientApp = () => {
  const { isLoggedIn } = useUser();
  const pages = useAppPages();
  const appData = useAppData();
  const { pathname, hash, key } = useLocation();

  const isSimpleDesign = useMemo(() => pathname.includes('status'), [pathname]);
  const isHelloLoading = useAppSelector((state) =>
    selectProfileLoading(state, 'hello'),
  );
  const isAppLoading = useMemo(
    () => isHelloLoading || !isLoggedIn,
    [isHelloLoading, isLoggedIn],
  );

  //HASH
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '' && window !== undefined) {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  useEffect(() => {
    const { setHeader, setNav, setBottomNav } = appData;
    if (!isSimpleDesign) {
      setHeader(<PageHeader />);
      setNav(<Navigation />);
      setBottomNav(<BottomNavigation />);
    } else {
      setHeader(<PageHeader />);
      setNav(<Navigation />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isSimpleDesign]);

  return (
    <>
      <AppLayout isLoading={isAppLoading}>
        <AppRoutesRender pages={pages} defaultHomepage={'/dashboard'} />
      </AppLayout>
      <RfpActionModal />
      <ProviderActionModal />
    </>
  );
};

export default ClientApp;
