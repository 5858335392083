import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RfpStatusFlowSteps } from '.';
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTheme } from '../../../plugins/styled';
import Button from '../../common/button';
import Div from '../../common/div';
import Modal from '../../common/hd-modal';
import RfpStatusFlowLayout from './rfp-status-flow-layout';
const RfpStatusFlowModal = ({ open, handleClose, handleClick, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    return (_jsx(Modal, { open: open, handleCancel: handleClose, children: _jsx(Div, { flex: "column", align: "center", children: _jsx(RfpStatusFlowLayout, { title: "Tell us about the status of your project!", description: "We'd love to know more about where you're at with your project. Please take a minute to fill us in.", children: _jsxs(Div, { flex: "column", gap: 48, align: "center", children: [_jsxs(Div, { flex: "column", gap: 24, children: [_jsx(Button, { fullWidth: true, defaultStyle: theme.colors.green_1, onClick: () => handleClick(RfpStatusFlowSteps.PartnerChooseSelected), children: t('projectStatusFlow|psf_modal_button1') }), _jsx(Button, { fullWidth: true, onClick: () => handleClick(RfpStatusFlowSteps.CancelRfpFlow), children: t('projectStatusFlow|psf_modal_button2') })] }), _jsx(Button, { isLink: true, onClick: () => handleClick(RfpStatusFlowSteps.NoPartnerSelectedOptions), children: t('projectStatusFlow|psf_modal_underlink1') })] }) }) }) }));
};
export default RfpStatusFlowModal;
