import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Progress from 'antd/es/progress';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import { RfpProgressCardContainer } from './styles';
const RfpProgressCard = ({ title, value, onClick, selected, }) => {
    const theme = useTheme();
    const handleClick = () => {
        onClick(value);
    };
    return (_jsx(RfpProgressCardContainer, { onClick: handleClick, "$isSelected": selected, hoverable: true, style: { flex: 1, cursor: 'pointer' }, children: _jsxs(Div, { flex: "column", align: "center", gap: 24, children: [_jsx(Progress, { type: "circle", percent: value, success: { percent: value, strokeColor: theme.colors.green_1 }, trailColor: theme.colors.grey_5, strokeColor: theme.colors.purple_1 }), _jsx(Typography, { elementTheme: "body2", textAlign: "center", nowrap: true, children: title })] }) }));
};
export default RfpProgressCard;
