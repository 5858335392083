import { AdminProjectResponse } from '@hellodarwin/core/lib/features/entities/projects-entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useMemo } from 'react';
import { ProjectSingleSectionProps } from '..';
import ApplicationsSection from './applications-section';

export enum ProjectSingleSections {
  Applications = 'applications',
}

export interface ProjectSingleSectionMapping {
  [key: string]: ProjectSingleSectionProps;
}

export interface ProjectsSectionBlockProps {
  project: AdminProjectResponse;
}

const useProjectBlocks = (
  props: ProjectsSectionBlockProps,
): ProjectSingleSectionMapping => {
  const { t } = useTranslations();

  return useMemo(() => {
    const blocks: ProjectSingleSectionMapping = {
      [ProjectSingleSections.Applications]: {
        label: t('project|project_applications'),
        children: <ApplicationsSection {...props} />,
      },
    };
    return blocks;
  }, [props, t]);
};

export default useProjectBlocks;
