import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Checkmark from '@hellodarwin/icons/dist/icons/Checkmark';
import parse from '../../../../features/helpers/parse';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
const CancelConfirmation = ({ handleClose }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    return (_jsx(Div, { borderRadius: 24, className: "fade-in", style: { maxWidth: 600 }, children: _jsxs(Div, { flex: "column", align: "center", gap: 20, children: [_jsx(Checkmark, { style: { color: theme.colors.green_1 }, width: 64, height: 64 }), _jsxs(Div, { flex: "column", gap: 16, align: "center", children: [_jsx(Typography.Title, { level: 1, elementTheme: "h5", textAlign: "center", children: t('projectStatusFlow|psf_cancelThankYou_header1') }), _jsx(Typography, { textAlign: "center", children: parse(t('projectStatusFlow|psf_cancelThankYou_text1')) })] }), _jsx(Button, { onClick: handleClose, isLink: true, children: t('button|close') })] }) }));
};
export default CancelConfirmation;
