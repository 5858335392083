import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import GreenCheck from '@hellodarwin/icons/dist/icons/GreenCheck';

interface SubmitRfpSuccessProps {
  handleClose: () => void;
}
const SubmitRfpSuccess = ({ handleClose }: SubmitRfpSuccessProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Div
      flex={'column'}
      justify={'center'}
      align={'center'}
      className="fade-in"
      gap={24}
    >
      <Div flex="column" gap={16} align="center">
        <GreenCheck size={70} color={theme.colors.green_1} />
        <Div flex="column" gap={8} align="center">
          <Typography elementTheme="h5" bold fitContent>
            {t('rfp|submitRfpForm.successModal.title')}
          </Typography>
          <Typography elementTheme="body1" textAlign={'center'}>
            {t('rfp|submitRfpForm.successModal.text')}
          </Typography>
        </Div>
      </Div>
      <Button
        onClick={handleClose}
        defaultStyle={theme.colors.primary}
        size="small"
      >
        Ok
      </Button>
    </Div>
  );
};

export default SubmitRfpSuccess;
