import { PropsWithChildren } from 'react';
import ProjectSingleSectionHeader from './project-single-section-header';
import { ProjectSingleSectionContainer } from './styles';

export interface ProjectSingleSectionProps extends PropsWithChildren {
  label: string;
}
const ProjectSingleSection: React.FC<ProjectSingleSectionProps> = (props) => {
  return (
    <ProjectSingleSectionContainer>
      <ProjectSingleSectionHeader {...props} />
      {!!props.children && <div>{props.children}</div>}
    </ProjectSingleSectionContainer>
  );
};

export default ProjectSingleSection;
