import { ProfileEditModalFunctions } from '@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-edit-modal';
import ProfileEditionProvider from '@hellodarwin/core/lib/features/providers/profile-edition-provider';
import { PropsWithChildren, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import {
  deleteLogo,
  selectProfileLoading,
  updateLogo,
} from '../api/slices/profile-slice';
import { useClientApi } from '../api/use-client-api';

interface ClientProfileEditionProviderProps extends PropsWithChildren {}

const ClientProfileEditionProvider: React.FC<
  ClientProfileEditionProviderProps
> = ({ children }) => {
  const api = useClientApi();
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectProfileLoading);

  const functions: ProfileEditModalFunctions = useMemo(
    () => ({
      isLoading,
      updateLogo: ({ companyId, data }) =>
        dispatch(updateLogo({ api, data, companyId })),
      deleteLogo: ({ companyId }) => dispatch(deleteLogo({ api, companyId })),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  return (
    <ProfileEditionProvider functions={functions}>
      {children}
    </ProfileEditionProvider>
  );
};

export default ClientProfileEditionProvider;
