import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Budget from '@hellodarwin/icons/dist/icons/Budget';
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import Industry from '@hellodarwin/icons/dist/icons/Industry';
import Invoices from '@hellodarwin/icons/dist/icons/Invoices';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { dollarFormat } from '../../../features/helpers';
import { getProgramGrantStatusColor } from '../../../features/helpers/get-status-color';
import { attributesToProps, domToReact, } from '../../../features/helpers/parse';
import useLocale from '../../../features/providers/locale-provider';
import { useTranslations } from '../../../features/providers/translations-provider';
import HdTag from '../../common/hd-tag';
import TagsList from '../../common/hd-tag/tags-list';
import Link from '../../common/link';
import Typography from '../../common/typography';
import ProfileLogo from '../../provider/profile/profile-assets/profile-logo';
import FundingAmount from './funding-amout';
import ProjectProgramInfo from './project-program-info';
import { ProjectProgramCardBody, ProjectProgramCardBodyContent, ProjectProgramCardBodyContentColumn, ProjectProgramCardContainer, ProjectProgramCardHeader, ProjectProgramCardHeaderInfos, } from './styles';
export const parseOptions = {
    replace: (domNode) => {
        if (domNode.attribs && domNode.name === 'a') {
            const props = attributesToProps(domNode.attribs);
            return (_jsx("a", Object.assign({}, props, { rel: "nofollow", target: "_blank", children: domToReact(domNode.children) })));
        }
        if (domNode.attribs && domNode.name === 'img') {
            return _jsx(_Fragment, {});
        }
    },
};
const ProjectProgramCard = ({ program, programPath, }) => {
    const { program_name, program_grant_status, grant_logo, grant_display_title, province, country, program_id, funding_min_amount, funding_max_amount, percentage_funding, grant_description_short, grant_industry_sectors, grant_financing_type, city, } = program;
    const theme = useTheme();
    const { t } = useTranslations();
    const { selectedLocale } = useLocale();
    const applicationAmountLabelAndValue = useMemo(() => {
        var _a, _b;
        const label = !!program.program_accepted_amount
            ? t('application|application_accepted_amount')
            : !!program.program_application_amount
                ? t('application|application_applied_amount')
                : !!program.program_targeted_amount
                    ? t('application|application_targeted_amount')
                    : undefined;
        const value = dollarFormat((_b = (_a = program.program_accepted_amount) !== null && _a !== void 0 ? _a : program.program_application_amount) !== null && _b !== void 0 ? _b : program.program_targeted_amount, selectedLocale, true);
        return { value, label };
    }, [program, selectedLocale]);
    return (_jsx(Link, { to: `${programPath}/${program_id}`, target: "_blank", children: _jsxs(ProjectProgramCardContainer, { children: [_jsxs(ProjectProgramCardHeader, { children: [_jsx(ProfileLogo, { name: [grant_display_title, city, province, country]
                                .filter((itm) => !!itm)
                                .join(' - '), logo: grant_logo, size: 110, type: 'grant-provider' }), _jsxs(ProjectProgramCardHeaderInfos, { children: [_jsx(HdTag, { color: getProgramGrantStatusColor(program_grant_status, theme), text: t(`programs_labels|${program_grant_status}`), withBorder: true }), _jsx(Typography.Title, { level: 3, elementTheme: "h6", children: program_name }), !!grant_description_short && (_jsx(Typography, { elementTheme: "body2", color: theme.colors.grey_2, children: grant_description_short }))] }), _jsx(NewWindow, { size: 20, style: { position: 'absolute', top: 16, right: 16 }, color: theme.colors.purple_2 })] }), _jsx(ProjectProgramCardBody, { children: _jsxs(ProjectProgramCardBodyContent, { children: [_jsxs(ProjectProgramCardBodyContentColumn, { children: [_jsx(ProjectProgramInfo, { label: t('grant_single|eligibleFinancing'), Icon: Budget, value: _jsx(FundingAmount, { funding_max_amount: funding_max_amount, funding_min_amount: funding_min_amount, percentage_funding: percentage_funding }) }), _jsx(ProjectProgramInfo, { label: applicationAmountLabelAndValue.label, Icon: Grants, value: _jsx("ul", { children: _jsx("li", { style: { color: theme.colors.purple_2, fontWeight: 500 }, children: applicationAmountLabelAndValue.value }) }) })] }), ' ', _jsxs(ProjectProgramCardBodyContentColumn, { children: [_jsx(ProjectProgramInfo, { label: t('grant_card|eligibleIndustries'), Icon: Industry, value: _jsx("ul", { style: { width: '100%' }, children: !!(grant_industry_sectors === null || grant_industry_sectors === void 0 ? void 0 : grant_industry_sectors.length) ? (_jsx(_Fragment, { children: React.Children.toArray(grant_industry_sectors
                                                    .slice(0, 4)
                                                    .map((tag) => _jsx("li", { children: tag }, tag))) })) : (_jsx("li", { style: { width: '100%' }, children: t('grant_card|allIndustries') })) }) }), _jsx(ProjectProgramInfo, { label: t('grant_card|financingType'), Icon: Invoices, value: _jsx(TagsList, { mainColor: theme.colors.purple_3, limit: 3, tags: grant_financing_type === null || grant_financing_type === void 0 ? void 0 : grant_financing_type.map((text) => ({
                                                text,
                                            })) }) })] })] }) })] }) }));
};
export default ProjectProgramCard;
