import Container from '@hellodarwin/core/lib/components/common/container';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import BusinessProfile from '@hellodarwin/core/lib/components/company/business-profile';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import PrioritiesTable from '../../components/projects/priorities-table';
import {
  fetchCompany,
  selectCompany,
} from '../../features/api/slices/profile-slice';
import {
  fetchIndustries,
  selectIndustriesSectors,
  selectIndustriesSubsectors,
} from '../../features/api/slices/tags-slice';
import { useClientApi } from '../../features/api/use-client-api';

const ClientProjectsPage = () => {
  const { t } = useTranslation();
  const company = useAppSelector(selectCompany);

  const api = useClientApi();
  const dispatch = useAppDispatch();

  const { selectedLocale } = useLocale();

  const industries = useAppSelector(selectIndustriesSectors);
  const subindustries = useAppSelector(selectIndustriesSubsectors);

  useEffect(() => {
    dispatch(fetchIndustries({ api, locale: selectedLocale }));
    dispatch(fetchCompany({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  return (
    <PageLayout
      app="client"
      title={t('project|gamePlan')}
      breadcrumbs={[
        {
          breadcrumbName: t('navigation.home'),
          path: '/',
        },
        {
          breadcrumbName: t('project|gamePlan'),
        },
      ]}
    >
      <Container>
        <Typography elementTheme="h6">
          {t('project|businessProfile')}
        </Typography>
        <BusinessProfile
          company={company}
          industries={industries}
          subindustries={subindustries}
        />
      </Container>
      <Container>
        <Typography elementTheme="h6">
          {t('project|businessPriorities')}
        </Typography>
        <PrioritiesTable />
      </Container>
    </PageLayout>
  );
};

export default ClientProjectsPage;
