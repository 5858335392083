import { useAppDispatch, useAppSelector } from '../../../app/app-hooks';
import {
  selectRfpModal,
  toggleRfpsModal,
} from '../../../features/api/slices/rfp-slice';
import RfpAdditionalShortlistModal from './rfp-additional-shortlist';
import RfpManagerContactModal from './rfp-manager-contact-modal';
import RfpMoreCandidatesModal from './rfp-more-candidates';
import RfpStatusFlowModal from './rfp-status-flow-modal';
import ShortlistMoreCandidateModal from './shortlist-more-candidate-modal';
import RfpStatusModal from './status-modal';
import ClientSubmitRfpModal from './submit-rfp-modal';

const RfpActionModal = () => {
  const dispatch = useAppDispatch();
  const modal = useAppSelector(selectRfpModal);

  const handleClose = () => {
    dispatch(toggleRfpsModal({ isVisible: false, type: '', props: undefined }));
  };

  switch (modal.type) {
    case 'rfpSubmit':
      return (
        <ClientSubmitRfpModal
          open={modal.isVisible}
          handleClose={handleClose}
        />
      );
    case 'rfpStatusFlow':
      if (!modal.props) return <></>;

      return (
        <RfpStatusFlowModal
          open={modal.isVisible}
          handleClose={handleClose}
          state={modal.props}
        />
      );

    case 'manager':
      return (
        <RfpManagerContactModal
          open={modal.isVisible}
          handleClose={handleClose}
        />
      );

    case 'shortlistMore':
      return (
        <ShortlistMoreCandidateModal
          open={modal.isVisible}
          handleClose={handleClose}
        />
      );

    case 'moreCandidates':
      return (
        <RfpMoreCandidatesModal
          open={modal.isVisible}
          handleClose={handleClose}
        />
      );
    case 'additionalShortlisting':
      return (
        <RfpAdditionalShortlistModal
          open={modal.isVisible}
          handleCancel={handleClose}
        />
      );

    case 'rfpStatus':
      return (
        <RfpStatusModal open={modal.isVisible} handleClose={handleClose} />
      );

    default:
      return <></>;
  }
};

export default RfpActionModal;
